import { Button, Input, message, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { verifyUser } from "../../actions/user";

function VerificationModal({
  isVisible,
  secret,
  onVerify = () => {},
  onClose = () => {},
}) {
  const [code, setCode] = useState("");
  const dispatch = useDispatch();

  const handleVerification = async (code) => {
    const res = await dispatch(verifyUser({ secret: secret, code }));
    if (!res.error) {
      onVerify();
      return;
    }
    message.error(res.error_description);
    setCode("");
  };

  return (
    <Modal
      open={isVisible}
      footer={false}
      maskClosable={false}
      closable={true}
      maskStyle={{ zIndex: 9999 }}
      zIndex={9999}
      onCancel={onClose}
      centered
      style={{ borderRadius: 10, overflow: "hidden" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ fontSize: 22, fontWeight: "bold", marginBottom: 15 }}>
          {"Two-Step Verification"}
        </div>
        <div style={{ textAlign: "center", marginBottom: 10 }}>
          {"please enter your code to verify your login"}
        </div>
        <Input
          style={{ width: 250, marginBottom: 20 }}
          value={code}
          placeholder="Verification code"
          maxLength={6}
          onEnt
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
        <Button
          disabled={code.length < 6}
          onClick={() => handleVerification(code)}
          type="primary"
          color="black"
          style={{
            backgroundColor: code.length < 6 ? "grey" : "#000",
            color: "#fff",
            marginTop: 20,
            border: "none",
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}

export default VerificationModal;
