import { apiRequest } from "../api";
import apiPaths from "../api/paths";
import {
  SET_LEADS,
  UPDATE_LEAD_DATA,
  ADD_LEAD,
  UPDATE_LEADS_DATA,
  DELETE_LEADS,
  SET_LEADS_FILTERS,
  SET_LEADS_PAGINATION,
} from "./types";

export const getLeads = (queryPath = "") => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.leads + queryPath,
    }).then((res) => {
      if (!res.error) {
        dispatch({
          type: SET_LEADS,
          data: res.results,
        });
        dispatch({
          type: SET_LEADS_PAGINATION,
          data: res.pagination,
        });
      }
      return res;
    });
  };
};

export const addNewLead = (data) => ({
  type: ADD_LEAD,
  data: data,
});

export const updateLeadData = (data) => ({
  type: UPDATE_LEAD_DATA,
  data: data,
});

export const updateLeadsData = (data) => ({
  type: UPDATE_LEADS_DATA,
  data: data,
});

const deleteLeadsById = (data) => ({
  type: DELETE_LEADS,
  data: data,
});

export const updateLead = (id, data) => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.leads + "/" + id,
      data: data,
      method: "PUT",
    }).then(async (res) => {
      if (!res.error) {
        await dispatch(updateLeadData(res));
      }
      return res;
    });
  };
};

export const assignUserToLeads = (data) => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.leads + "/multiple-assign",
      data: data,
      method: "POST",
    }).then(async (res) => {
      if (!res.error) {
        await dispatch(updateLeadsData(res.updated));
      }
      return res;
    });
  };
};

export const deleteLeads = (data) => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.leads,
      data: data,
      method: "DELETE",
    }).then(async (res) => {
      if (!res.error) {
        await dispatch(deleteLeadsById(data));
      }
      return res;
    });
  };
};

export const getLeadsFilters = () => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.leads + "/filters",
    }).then(async (res) => {
      if (!res.error) {
        await dispatch({
          type: SET_LEADS_FILTERS,
          data: res,
        });
      }
      return res;
    });
  };
};
