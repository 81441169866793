import { useSelector } from "react-redux";
import "antd/dist/antd.min.css";
import "./App.css";
import PublicPages from "./pages/Public";
import Roles from "./pages/Roles";
import WebSocket from "./pages/WebSocket";

function App() {
  const isUserLoggedin = useSelector((state) => state.token.tokenData);
  return (
    <div className="app flex">
      {isUserLoggedin ? (
        <>
          <Roles />
          <WebSocket />
        </>
      ) : (
        <PublicPages />
      )}
    </div>
  );
}

export default App;
