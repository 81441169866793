import { BellFilled } from "@ant-design/icons";
import { notification, Space } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { addNewLead, updateLeadData } from "../actions/lead";
import { addUserNotification } from "../actions/user";
import { GenerateNotification } from "../common";
import { APP_MODE, WEBSOCKET_URL } from "../config";

function WebSocket() {
  const isUserLoggedin = useSelector((state) => state.token.tokenData);
  const userData = useSelector((state) => state.token.userData);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const socketInitializer = (socket) => {
    socket.on("connect", (data) => {
      //connected
    });
    socket.on("lead-update", (data) => {
      dispatch(updateLeadData(data.content));
      // api.open({
      //   description: "Lead updated",
      //   placement: "bottomLeft",
      //   duration: 10,
      // });
    });

    socket.on("new-lead", (data) => {
      dispatch(addNewLead(data.content));
      // api.open({
      //   description: "Added New Lead",
      //   placement: "bottomLeft",
      //   duration: 10,
      // });
    });

    socket.on("notification", (data) => {
      dispatch(addUserNotification(data));
      api.info({
        message: "New Notification",
        description: GenerateNotification(data),
        placement: "topRight",
        top: "80px",
        icon: (
          <BellFilled
            style={{
              fontSize: 40,
              color: "#4096FF",
              paddingRight: 10,
            }}
          />
        ),
        duration: 10,
      });
    });
  };

  useEffect(() => {
    if (APP_MODE == "dev") {
      return;
    }
    const socket = io(WEBSOCKET_URL, {
      auth: { token: isUserLoggedin?.access_token },
      path: "/socket/",
    });
    if (isUserLoggedin) {
      socketInitializer(socket);
    }
    return () => {
      socket.off("notification");
    };
  }, [isUserLoggedin]);

  return <Space>{contextHolder}</Space>;
}

export default WebSocket;
