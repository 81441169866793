import { apiRequest } from "../api";
import apiPaths from "../api/paths";
import { SET_BOOKINGS, UPDATE_BOOKING } from "./types";

export const getBookings = () => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.bookings,
    }).then((res) => {
      if (!res.error) {
        dispatch({
          type: SET_BOOKINGS,
          data: res.results,
        });
      }
      return res;
    });
  };
};

export const updateBooking = (id, formData) => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.bookings + `/${id}`,
      formData,
      method: "PUT",
    }).then((res) => {
      if (!res.error) {
        dispatch({
          type: UPDATE_BOOKING,
          data: res,
        });
      }
      return res;
    });
  };
};
