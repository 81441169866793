import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
} from "antd";
import moment from "moment";
import { apiRequest } from "../../../api";
import apiPaths from "../../../api/paths";
import SearchSelect from "../../../components/SearchSelect";

function StatusChange({
  open = false,
  onSave = () => {},
  onCancel = () => {},
}) {
  const getOptions = async (searchText, optionObj = {}) => {
    const { type, valueKey = "id", labelKey = "title" } = optionObj;
    let url = apiPaths.statuses + `?status_contains=${searchText}`;

    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    return options.map((item, index) => ({
      ...item,
      key: index,
      value: item[valueKey],
      _id: item[valueKey],
      label: `${item[labelKey]}`,
    }));
  };

  const handleOnSave = async (values) => {
    values.status = values.status.value;
    onSave(values);
  };

  return (
    <Modal
      title={"Add Follow Up Info"}
      width={900}
      visible={open}
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" onFinish={handleOnSave}>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Follow Up Date"
              tooltip="Choose Follow Up Date"
              name="followUpDate"
              rules={[
                {
                  required: true,
                  message: "Please select followup date",
                },
              ]}
            >
              <DatePicker
                disabledDate={(current) =>
                  current.isBefore(moment().subtract(1, "day"))
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              label="Status"
              tooltip="Choose Status (Need to type atleast 3 character) "
              name="status"
              rules={[
                {
                  required: true,
                  message: "Please select status",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "310px" }}
                placeholder={"Search by status"}
                fetchOptions={async (text) => {
                  return getOptions(text, {
                    type: "status",
                    valueKey: "statusId",
                    labelKey: "status",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Follow Up Summary"
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Please input Summary",
                },
              ]}
            >
              <Input.TextArea
                style={{ height: 120 }}
                placeholder="Summary here..."
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              {"Submit"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default StatusChange;
