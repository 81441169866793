import { combineReducers } from "redux";
import leadReducer from "./lead";
import tokenReducer from "./token";
import userReducer from "./user";
import { REMOVE_USER_TOKEN_DATA } from "../actions/types";

const appReducer = combineReducers({
  token: tokenReducer,
  lead: leadReducer,
  user: userReducer,
});

const rootReducer = (state, action) => {
  if (action.type === REMOVE_USER_TOKEN_DATA) {
    state = undefined; // Reset the state
  }
  return appReducer(state, action);
};

export default rootReducer;
