import { Form, Input, Button, message, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser, removeUserData } from "../../actions/user";
import images from "../../assets/images";
import classNames from "../../styles/styles.module.css";
import VerificationModal from "./VerificationModal";

function Login() {
  const dispatch = useDispatch();
  const [showVerification, setShowVerification] = useState(null);

  const handleVerification = async () => {
    setShowVerification(null);
  };

  const onFinish = (values) => {
    dispatch(
      loginUser({
        username: values.username,
        password: values.password,
      })
    ).then((res) => {
      if (res.error) {
        message.error(res.error_description);
        return;
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div
      className="flex shadow"
      style={{
        width: "90%",
        height: "90%",
        flexDirection: "column",
        backgroundColor: "#fff",
        padding: "5%",
      }}
    >
      <img src={images.logo} style={{ height: 100 }} />
      <Typography.Title>Admin Panel</Typography.Title>
      <div
        className="flex"
        style={{
          marginTop: 25,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          className={classNames.loginForm}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={"Username"}
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8 }}>
              <Form.Item wrapperCol={{ offset: 8 }} style={{ marginTop: 10 }}>
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form.Item>
          </Form>
        </div>
      </div>
      <VerificationModal
        isVisible={showVerification}
        onClose={() => {
          setShowVerification(null);
        }}
        secret={showVerification}
        onVerify={handleVerification}
      />
    </div>
  );
}

export default Login;
