import {
  CheckOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Menu,
  message,
  Popconfirm,
  Popover,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { deleteLeads, getLeads, updateLead } from "../../actions/lead";
import DynamicTable from "../../components/DynamicTable";
import AssignUser from "./components/AssignUser";
import BookingModal from "./components/BookingModal";
import Filters from "./components/Filters";
import FollowUps from "./components/FollowUps";
import LeadModal from "./components/LeadModal";
import * as XLSX from "xlsx";
import download from "downloadjs";

const DEFAULT_COLUMNS = {
  assignedDate: {
    show: false,
    label: "Assigned Date",
    key: "assignedDate",
  },

  assignedTo: {
    show: true,
    label: "Assigned To",
    key: "assignedTo",
  },
  bhk_info: {
    show: false,
    label: "Type",
    key: "bhk_info",
  },
  city: {
    show: true,
    label: "City",
    key: "city",
  },
  comment: {
    show: true,
    label: "Description",
    key: "comment",
  },
  createdBy_info: {
    show: true,
    label: "Lead Owner",
    key: "createdBy_info",
  },
  createdDtm: {
    show: true,
    label: "Created Date",
    key: "createdDtm",
  },
  developer: {
    show: true,
    label: "Developer",
    key: "developer",
  },
  email: {
    show: false,
    label: "Email1",
    key: "email",
  },
  endDtm: {
    show: false,
    label: "End Date",
    key: "endDtm",
  },
  lead_source_info: {
    show: false,
    label: "Lead Source",
    key: "lead_source_info",
  },
  permalink: {
    show: false,
    label: "Permalink",
    key: "permalink",
  },
  priority: {
    show: false,
    label: "Priority",
    key: "priority",
  },
  project: {
    show: true,
    label: "Project",
    key: "project",
  },
  statusId: {
    show: true,
    label: "Status",
    key: "statusId",
  },
  title: {
    show: true,
    label: "Name",
    key: "title",
  },
  subcity: {
    show: false,
    label: "Sub City",
    key: "subcity",
  },
};

let EXPORT_COLUMNS = {
  Name: (data) => data.title,
  Contact: (data) => `${data.contact1} | ${data.contact2 || ""}`,
  Email: (data) => `${data.email} | ${data.email2 || ""}`,
  "Last Follow Ups": (data) => {
    return data.follow_up?.reduce((str, item) => {
      str += `${item.comment}  | ${moment(item.createdDate).format(
        "DD-MM-YYYY hh:mm a"
      )} \n`;
      return str;
    }, "");
  },

  Developer: (data) => data.developer_info?.title,
  Project: (data) => data.project_info?.title,
  Query: (data) => data.comment,
  Status: (data) => data.status?.status,
  "Lead Source": (data) => data.lead_source_info?.lead_source,
  Manager: (data) => data.manager_info?.name,
  "Assigned To": (data) => data.assignedTo_info?.name,
  "Created Date": (data) =>
    moment(data.createdDtm).format("DD-MM-YYYY hh:mm a"),
  "Lead Owner": (data) => data.createdBy_info?.name,
  Deleted: (data) => data.is_deleted || "",
};

function Total({ count }) {
  return (
    <span
      style={{
        position: "absolute",
        top: -10,
        left: 5,
        fontWeight: "bold",
        zIndex: 11,
      }}
    >
      {`Total: ${count}`}
    </span>
  );
}

function Leads() {
  const [showLeadModal, setShowLeadModal] = useState(false);
  const [showFollowUps, setShowFollowUps] = useState(false);
  const [showAssignUser, setShowAssignUser] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const leads = useSelector((state) => state.lead.leads) || [];
  const [looading, setLoading] = useState(false);
  const leadsPagination =
    useSelector((state) => state.lead.leadsPagination) || {};
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.token.userData);
  let isAdmin = userData.roleId == 1;
  const location = useLocation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});

  const filters = useSelector((state) => state.lead.filters) || {};

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const fetchLeads = async (queryPath = "") => {
    setLoading(true);
    await dispatch(getLeads(queryPath)).then((res) => {
      if (res.error) {
        message.error(res.error_description);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    let filteredValues = location.state?.filteredValues || {};

    handleOnChange(null, filteredValues, null, { action: "filter" });
  }, [location.state]);

  const getColor = (colorClass) => {
    switch (colorClass) {
      case "danger":
        return "red";
      default: {
        return colorClass;
      }
    }
  };

  const handleGetBack = async (data) => {
    const res = await dispatch(updateLead(data.id, { is_deleted: false }));
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    message.success("Lead updated");
  };

  const Content = ({ data }) => {
    return (
      <div>
        <a style={{ color: "purple" }}>{data.title}</a>
        {" | "}
        <br />
        <a>{data.contact1}</a>
        {" | "}
        <br />
        <a>{data.email}</a>
        {" | "}
        <div style={{ marginTop: 5 }}>
          <a
            onClick={() => {
              window.open(`tel:${data.contact1}`);
            }}
          >
            <img
              src={"/assets/imgs/call.png"}
              style={{ width: 20, height: 20 }}
            />
          </a>
          <a
            onClick={() => {
              window.open(`https://wa.me/${data.contact1}`);
            }}
            style={{ marginLeft: 15 }}
          >
            <img
              src={"/assets/imgs/whatsapp.png"}
              style={{ width: 23, height: 23 }}
            />
          </a>
        </div>
      </div>
    );
  };

  const COLUMNS = [
    {
      title: "Name",
      dataIndex: "title",
      width: 120,
      render: (_, record) => {
        return (
          <div>
            {record.status?.status == "New Lead" ? (
              <div
                style={{
                  position: "absolute",
                  width: 5,
                  height: 5,
                  borderRadius: 3,
                  backgroundColor: "blue",
                  right: 10,
                  top: 10,
                }}
              />
            ) : null}
            <Popover content={<Content data={record} />}>
              <a style={{ color: "purple", borderBottom: "1px solid purple" }}>
                {record.title}
              </a>
            </Popover>
            <Tooltip title="Project">
              <div
                style={{ fontSize: 12 }}
              >{`( ${record.project_info?.title} )`}</div>
            </Tooltip>
            {record.nextFollowUpDate ? (
              <Tooltip title="Next follow up date">
                <div style={{ fontSize: 10, color: "green" }}>{`${moment(
                  record.nextFollowUpDate
                ).format("DD-MM-YYYY hh:mm a")}`}</div>
              </Tooltip>
            ) : null}
            {record.is_deleted ? (
              <>
                <div
                  style={{
                    position: "absolute",
                    color: "red",
                    right: 0,
                    top: 0,
                  }}
                >
                  deleted
                </div>
                <div style={{ zIndex: 1, position: "relative" }}>
                  <Popconfirm
                    title="Are you sure to get this lead back?"
                    onConfirm={() => handleGetBack(record)}
                    okText="Get Back"
                  >
                    <Button
                      size="small"
                      type="primary"
                      style={{
                        backgroundColor: "#000000",
                        borderColor: "#000000",
                      }}
                    >
                      Get it Back
                    </Button>
                  </Popconfirm>
                </div>
              </>
            ) : null}
          </div>
        );
      },
      onFilter: (value, record) => {
        value = value.toLowerCase();
        return (
          record.title?.toLowerCase().includes(value) ||
          record.contact1?.toLowerCase().includes(value) ||
          record.email?.toLowerCase().includes(value)
        );
      },
      filterType: "search",
    },
    {
      title: "Last Follow Up",
      dataIndex: "status.status",
      key: "status",
      width: 220,
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {record.follow_up?.length ? (
            <div>
              {record.follow_up.map((item, idx) => (
                <div key={idx + "f"}>
                  <Typography.Paragraph
                    style={{ marginBottom: 0, fontSize: 12 }}
                    ellipsis={{
                      rows: 2,
                    }}
                  >
                    {record.follow_up.length - idx}
                    {". "}
                    {item.comment}
                  </Typography.Paragraph>
                  <div style={{ fontSize: 9, color: "#a7a7a7" }}>
                    {"UD: "}
                    {moment(item.createdDate).format("DD-MM-YYYY hh:mm a")}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div style={{ fontSize: 12 }}>No Follow up given</div>
          )}
          <div>
            <Button
              onClick={() => {
                setShowFollowUps(record);
              }}
              size="small"
              type="link"
            >
              <EditOutlined style={{ fontSize: 20 }} />
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "Query",
      dataIndex: "comment",
      width: 220,
      // filterType: "search",
      render: (value) => {
        return (
          <Typography.Paragraph
            ellipsis={{
              rows: 2,
            }}
            style={{ fontSize: "0.8rem" }}
          >
            {value}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status.status",
      key: "status",
      width: 150,
      filterType: "filter",
      filters: filters.status || [],
      filteredValue: filteredInfo.status || null,
      render: (_, record) => (
        <Tag color={getColor(record.status?.class)}>
          {record.status?.status}
        </Tag>
      ),
      onFilter: null,
    },
    {
      title: "Developer",
      dataIndex: "developer_info.title",
      key: "developer",
      width: 120,
      onFilter: null,
      filteredValue: filteredInfo.developer || null,
      filterType: "filter",
      filters: filters.developer || [],
      render: (_, record) => record.developer_info?.title,
    },
    {
      title: "Project",
      dataIndex: "project_info.title",
      key: "project",
      width: 100,
      onFilter: null,
      filterType: "filter",
      filteredValue: filteredInfo.project || null,
      filters: filters.project || [],
      render: (_, record) => record.project_info?.title,
    },
    {
      title: "Unit Type",
      dataIndex: "bhk_info.title",
      width: 120,
      key: "bhk",
      // filterType: "filter",
      // filters: filters.type || [],
      render: (_, record) => record.bhk_info?.title,
    },
    {
      title: "City",
      dataIndex: "city_info.city",
      key: "city",
      width: 120,
      filterType: "filter",
      onFilter: null,
      filteredValue: filteredInfo.city || null,
      filters: filters.city || [],
      render: (_, record) => record.city_info?.city,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "city",
      width: 120,
      // filterType: "search",
      render: (_, record) => (
        <div>
          <div>{record.email + " | "}</div>
          {record.email2 ? <div>{record.email2 + " | "}</div> : null}
        </div>
      ),
    },
    {
      title: "Subcity",
      dataIndex: "subcity",
      width: 120,
      // filterType: "search",
    },
    ...(userData.roleId == 1
      ? [
          {
            title: "Manager",
            dataIndex: "manager_info.name",
            key: "manager",
            width: 150,
            filterType: "filter",
            onFilter: null,
            filteredValue: filteredInfo.manager || null,
            filters: filters.manager || [],
            render: (_, record) => {
              return record.manager_info ? record.manager_info.name : "---";
            },
          },
        ]
      : []),
    {
      title: "Lead Owner",
      dataIndex: "createdBy_info.name",
      key: "createdBy",
      width: 120,
      filterType: "filter",
      onFilter: null,
      filteredValue: filteredInfo.createdBy || null,
      filters: filters.user || [],
      render: (_, record) => record.createdBy_info?.name,
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo_info.name",
      key: "assignedTo",
      filterType: "filter",
      filteredValue: filteredInfo.assignedTo || null,
      onFilter: null,
      filters: filters.user || [],
      width: 150,
      render: (_, record) => {
        return record.assignedTo_info ? record.assignedTo_info.name : "---";
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdDtm",
      filterType: "date",
      width: "150px",
      render: (value) => {
        return moment(value).format("DD-MM-YYYY hh:mm a");
      },
    },
    {
      title: "Assigned Date",
      dataIndex: "assignedDate",
      filterType: "date",
      filteredValue: filteredInfo.assignedDate || null,
      width: "180px",
      render: (value) => {
        return value && moment(value).format("DD-MM-YYYY hh:mm a");
      },
    },

    {
      title: "Lead Source",
      dataIndex: "lead_source_info.lead_source",
      key: "lead_source",
      width: 160,
      filteredValue: filteredInfo.lead_source || null,
      filters: filters.lead_source || [],
      filterType: "filter",
      onFilter: null,
      render: (_, record) => record.lead_source_info?.lead_source,
    },
    {
      title: "Permalink",
      dataIndex: "permalink",
      width: 120,
      // filterType: "search",
    },
    {
      title: "End Date",
      dataIndex: "endDtm",
      filterType: "date",
      width: "180px",
      render: (value) => {
        return value && moment(value).format("DD-MM-YYYY hh:mm a");
      },
    },
    {
      title: "Priority",
      dataIndex: "priority.priority",
      key: "priority",
      width: 120,
      // filters: filters.priority || [],
      // filterType: "filter",
      fixed: "right",
      render: (_, record) => {
        switch (record.priority?.priority) {
          case "Urgent":
            return <Tag color="red">Urgent</Tag>;
          case "Middle":
            return <Tag color="orange">Middle</Tag>;
          case "Normal":
            return <Tag color="blue">Normal</Tag>;
          default:
            return null;
        }
      },
    },
    {
      title: "Booking Details",
      dataIndex: "booking",
      key: "booking",
      width: 120,
      render: (booking, record) => (
        <Button
          shape="circle"
          type="dashed"
          onClick={() => {
            setShowBookingModal(record);
          }}
        >
          {booking ? <CheckOutlined /> : <PlusOutlined />}
        </Button>
      ),
    },
  ];

  const handleDelete = async () => {
    var res = await dispatch(
      deleteLeads({
        lead_ids: selectedRowKeys,
      })
    );
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    message.success("Leads Deleted");
  };

  const downLoadFile = async (items) => {
    let itemsRef = items.slice(0);

    let jsonData = itemsRef.map((item) => {
      let formatedItem = Object.keys(EXPORT_COLUMNS).reduce((obj, key) => {
        obj[key] = EXPORT_COLUMNS[key](item);
        return obj;
      }, {});
      return formatedItem;
    });
    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(jsonData);
    var range = XLSX.utils.decode_range(worksheet["!ref"]);
    worksheet["!cols"] = [...Array(range.e.c)].map(() => ({ wch: 20 }));
    XLSX.utils.book_append_sheet(workbook, worksheet, "sheet_1");
    let xlBuffer = XLSX.write(workbook, { type: "array", bookType: "xlsx" });
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";
    const data = new Blob([xlBuffer], { type: fileType });
    download(data, `leads.xlsx`, fileType);
  };

  let menuItems = [
    {
      label: "Export",
      key: "1",
      icon: <ExportOutlined />,
      onClick: () => downLoadFile(leads),
      disabled: !leads?.length,
    },
  ];
  const menu = (
    <Menu>
      {menuItems.map((prop) => (
        <Menu.Item {...prop}>{prop.label}</Menu.Item>
      ))}
    </Menu>
  );

  const handleOnChange = (pagination, filters, sorter, extra) => {
    let queryPath = `?page=${pagination?.current || 1}`;
    if (extra?.action == "paginate") {
      filters = filteredInfo;
    }
    if (filters.title) {
      queryPath += `&title_contain=${filters.title[0]}`;
    }
    if (filters.status) {
      queryPath += `&status=${filters.status.join(",")}`;
    }
    if (filters.project) {
      queryPath += `&project=${filters.project.join(",")}`;
    }
    if (filters.developer) {
      queryPath += `&developer=${filters.developer.join(",")}`;
    }
    if (filters.city) {
      queryPath += `&city=${filters.city.join(",")}`;
    }
    if (filters.manager) {
      queryPath += `&manager=${filters.manager.join(",")}`;
    }
    if (filters.createdBy) {
      queryPath += `&createdBy=${filters.createdBy.join(",")}`;
    }
    if (filters.assignedTo) {
      queryPath += `&assignedTo=${filters.assignedTo.join(",")}`;
    }
    if (filters.lead_source) {
      queryPath += `&lead_source=${filters.lead_source.join(",")}`;
    }
    if (filters.createdDtm) {
      queryPath += `&created_date_start=${filters.createdDtm[0][0]}&created_date_end=${filters.createdDtm[0][1]}`;
    }
    if (filters.assignedDate) {
      queryPath += `&assigned_date_start=${filters.assignedDate[0][0]}&assigned_date_end=${filters.assignedDate[0][1]}`;
    }
    if (filters.follow_up) {
      queryPath += `&follow_up_start_date=${filters.follow_up[0]}&follow_up_end_date=${filters.follow_up[1]}`;
    }
    let updated = { ...filters, pagination };

    if (JSON.stringify(updated) == JSON.stringify(filteredInfo)) {
      return;
    }
    fetchLeads(queryPath);
    setFilteredInfo(updated);
  };

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          gap: 5,
        }}
      >
        <Filters
          onFilter={(filters) => handleOnChange(null, filters, null, null)}
          onClearFilter={() => {
            handleOnChange(null, {}, null, null);
          }}
          options={filters}
          initialValue={filteredInfo}
        />
        <Space wrap={true}>
          {isAdmin ? (
            <Popconfirm
              title="Are you sure to delete, these leads?"
              onConfirm={handleDelete}
              okText="Delete"
              disabled={!selectedRowKeys.length}
            >
              <Tooltip title="Delete Leads">
                <Button
                  icon={<DeleteOutlined />}
                  disabled={!selectedRowKeys.length}
                />
              </Tooltip>
            </Popconfirm>
          ) : null}
          <Tooltip title="Assign User">
            <Button
              icon={<UserAddOutlined />}
              disabled={!selectedRowKeys.length}
              onClick={() => {
                setShowAssignUser(true);
              }}
            />
          </Tooltip>
          {isAdmin ? (
            <Tooltip title="Download Leads">
              <Button
                icon={<DownloadOutlined />}
                onClick={() => {
                  downLoadFile(leads);
                }}
                disabled={!leads?.length}
              />
            </Tooltip>
          ) : null}

          <Tooltip title="Add Lead">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setShowLeadModal(true);
              }}
            />
          </Tooltip>
        </Space>
      </div>
      <div style={{ height: "86%", marginTop: 10, position: "relative" }}>
        <Total count={leadsPagination.total} />
        <DynamicTable
          loading={looading}
          rowClassName={(record, index) =>
            record.is_deleted
              ? "table-row-deleted"
              : record.leadCount > 1
              ? "table-row-colord"
              : ""
          }
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          dataSource={leads?.results || leads}
          columns={COLUMNS}
          defaultColumns={DEFAULT_COLUMNS}
          // pagination={false}
          editHandler={(record) => setShowLeadModal(record)}
          pagination={{
            current: filteredInfo.pagination?.current || 1,
            pageSize: 200,
            total: leadsPagination.total,
          }}
          onChange={handleOnChange}
          requireEdit={true}
          scroll={{ y: "95%" }}
        />
      </div>
      {showLeadModal ? (
        <LeadModal
          open={true}
          onSave={() => {
            setShowLeadModal(false);
          }}
          onCancel={() => {
            setShowLeadModal(false);
          }}
          data={showLeadModal.id ? showLeadModal : false}
        />
      ) : null}
      {showBookingModal ? (
        <BookingModal
          open={true}
          onSave={() => {
            setShowBookingModal(false);
          }}
          leadId={showBookingModal.id}
          booking={showBookingModal.booking}
          onCancel={() => {
            setShowBookingModal(false);
          }}
        />
      ) : null}
      {showFollowUps ? (
        <FollowUps
          open={true}
          leadId={showFollowUps.id}
          onClose={() => {
            setShowFollowUps(false);
          }}
        />
      ) : null}
      {showAssignUser ? (
        <AssignUser
          multiLead={selectedRowKeys}
          open={true}
          lead={showAssignUser}
          onCancel={() => {
            setShowAssignUser(false);
          }}
          onSave={() => {
            setShowAssignUser(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default Leads;
