import { Button, Col, Form, Input, message, Row, Space } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { apiRequest } from "../../api";
import apiPaths from "../../api/paths";

const initialContent = {
  subject: "Lead Registration | Propeve Realty",
  body: `Dear Team,

Greetings of the day!!
  
Kindly Register the below lead under PROPEVE Reality.
  
Name: 
Contact: 
Email: 
Project: 
  
  
Thanks & Regards
Team Propeve
Mobile Number: +919168215551`,
};

function Email() {
  const [form] = Form.useForm();
  const location = useLocation();
  const state = location.state || {};
  const [loading, setLoading] = useState(false);

  const handleOnSave = async (values) => {
    setLoading(true);
    let res = await apiRequest({
      path: apiPaths.mail,
      method: "POST",
      data: values,
    });
    setLoading(false);
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    message.success("Mail sent successfully");
    form.resetFields();
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Form
        form={form}
        initialValues={{
          ...initialContent,
          ...state,
        }}
        layout="vertical"
        onFinish={handleOnSave}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="To"
              name="to"
              rules={[
                {
                  required: true,
                  message: `Please input To`,
                },
              ]}
            >
              <Input placeholder="someone@gmail.com" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="CC" name="cc">
              <Input placeholder="someone@gmail.com" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Subject"
          name="subject"
          rules={[
            {
              required: true,
              message: `Please input Subject`,
            },
          ]}
        >
          <Input placeholder="subject here..." />
        </Form.Item>
        <Form.Item
          label="Body"
          name="body"
          rules={[
            {
              required: true,
              message: `Please input body`,
            },
          ]}
        >
          <Input.TextArea rows={16} placeholder="body here..." />
        </Form.Item>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                form.resetFields();
              }}
            >
              Clear
            </Button>
            <Button type="primary" loading={loading} htmlType="submit">
              {"Send"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Email;
