import { PlusOutlined } from "@ant-design/icons";
import { Button, message, Tooltip } from "antd";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { apiRequest } from "../../api";
import apiPaths from "../../api/paths";
import DynamicTable from "../../components/DynamicTable";
import ProjectModal from "./components/ProjectModal";

function Projects() {
  const [editingKey, setEditingKey] = useState(null);
  const [projects, setProjects] = useState({});
  const [showProjectModal, setShowProjectModal] = useState(false);
  const loadingRef = useRef(true);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    const res = await apiRequest({
      path: apiPaths.projects,
    });
    loadingRef.current = false;
    if (!res.error) {
      let projectsObj = res.results.reduce((projects, user) => {
        projects[user.id + "d"] = user;
        return projects;
      }, {});
      setProjects(projectsObj);
    } else {
      message.error(res.error_description);
    }
  };

  const COLUMNS = [
    {
      title: "Title",
      dataIndex: "title",
      width: 170,
      filterType: "search",
    },
    {
      title: "Locality",
      dataIndex: "details",
      width: "150px",
      filterType: "search",
    },
    {
      title: "Developer",
      dataIndex: "developer_info.title",
      width: "150px",
      filterType: "search",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value) => (value ? "Active" : "In Active"),
      // filterType: "search",
      width: "120px",
    },
    {
      title: "City",
      dataIndex: "city_info.city",
      width: "120px",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      width: "120px",
      filterType: "date",
    },
    {
      title: "Updated Date",
      dataIndex: "updatedDate",
      width: "120px",
      filterType: "date",
    },
  ];

  const handleRowCanceled = () => {
    setShowProjectModal(false);
  };

  const handleRowEdit = (idx) => {
    setEditingKey(idx);
    setShowProjectModal(true);
  };

  const handleRowSave = async (data) => {
    let updatedProjects = { ...projects };
    if (updatedProjects[data.id + "d"]) {
      updatedProjects[data.id + "d"] = { ...data };
    } else {
      updatedProjects = { [data.id + "d"]: data, ...projects };
    }
    setProjects(updatedProjects);
    setEditingKey(null);
    setShowProjectModal(false);
  };

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 10,
        }}
      >
        <Tooltip title="Add Project">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setShowProjectModal(true);
            }}
          />
        </Tooltip>
      </div>
      <div style={{ height: "90%", marginTop: 10 }}>
        <DynamicTable
          loading={loadingRef.current}
          dataSource={Object.values(projects)}
          columns={COLUMNS}
          rowKey="id"
          scroll={{
            // x: 2250,
            y: "90%",
          }}
          pagination={false}
          editHandler={(record) => handleRowEdit(record.id + "d")}
        />
      </div>
      {showProjectModal ? (
        <ProjectModal
          open={showProjectModal}
          onSave={handleRowSave}
          onCancel={handleRowCanceled}
          data={editingKey ? projects[editingKey] : null}
        />
      ) : null}
    </div>
  );
}

export default Projects;
