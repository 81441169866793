import { Button, Col, Form, Input, message, Modal, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateLead } from "../../../actions/lead";
import { apiRequest } from "../../../api";
import apiPaths from "../../../api/paths";
import SearchSelect from "../../../components/SearchSelect";

function LeadModal({
  open = false,
  data,
  onSave = () => {},
  onCancel = () => {},
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const developerValue = Form.useWatch("developer", form);
  const projectValue = Form.useWatch("project", form);

  const getOptions = async (searchText, optionObj = {}) => {
    const { type, valueKey = "id", labelKey = "title" } = optionObj;
    let url = "";
    switch (type) {
      case "developer": {
        url = apiPaths.developers + `?title_contains=${searchText}`;
        break;
      }
      case "category": {
        url = apiPaths.categories + `?title_contains=${searchText}`;
        break;
      }
      case "city": {
        url = apiPaths.cities + `?city_contains=${searchText}`;
        break;
      }
      case "lead_source": {
        url = apiPaths.leadSources + `?lead_source_contains=${searchText}`;
        break;
      }
      case "status": {
        url = apiPaths.statuses + `?status_contains=${searchText}`;
        break;
      }
      case "project": {
        url = apiPaths.projects + `?title_contains=${searchText}`;
        break;
      }
      case "priority": {
        url = apiPaths.priorities + `?priority_contains=${searchText}`;
        break;
      }
      case "user": {
        url = apiPaths.users + `?roleId=3&name_contains=${searchText}`;
        break;
      }
      case "manager": {
        url = apiPaths.users + `?roleId=2&name_contains=${searchText}`;
        break;
      }
      default: {
        return [];
      }
    }
    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    return options.map((item, index) => ({
      ...item,
      key: index,
      value: item[valueKey],
      _id: item[valueKey],
      label: `${item[labelKey]}`,
    }));
  };

  const handleOnSave = async (values) => {
    setLoading(true);
    if (values.assignedTo?.value) {
      values.assignedTo = values.assignedTo.value;
    }
    if (values.manager?.value) {
      values.manager = values.manager.value;
    }
    if (values.city?.value) {
      values.city = values.city.value;
    }
    if (values.priority?.value) {
      values.priorityId = values.priority.value;
      delete values.priority;
    }
    values.statusId = values.status.value;
    delete values.status;
    values.developer = values.developer.value;
    values.project = values.project.value;
    values.lead_source = values.lead_source.value;
    values.bhk = values.bhk.value;
    let res = {};
    if (data) {
      res = await dispatch(updateLead(data.id, values));
    } else {
      res = await apiRequest({
        path: apiPaths.leads,
        method: "POST",
        data: values,
      });
    }
    setLoading(false);
    if (!res.error) {
      message.success(data ? "Lead updated" : "Lead created successfully");
      onSave();
    } else {
      message.error(res.error_description);
    }
  };

  const getProjects = async (developer) => {
    let url = apiPaths.projects + `?developer=${developer}`;
    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    options = options.map((item, index) => ({
      ...item,
      key: index,
      value: item.id,
      _id: item.id,
      label: `${item.title}`,
    }));
    setProjects(options);
  };

  const handleSourceCreate = async (lead_source) => {
    let res = await apiRequest({
      path: apiPaths.leadSources,
      method: "POST",
      data: {
        lead_source,
      },
    });
    if (res.error) {
      message.error(res.error_description);
    }
  };

  useEffect(() => {
    if (developerValue) {
      getProjects(developerValue.value);
      if (projectValue && projectValue.developer != developerValue.value)
        form.setFieldValue("project", undefined);
    }
  }, [developerValue]);

  return (
    <Modal
      title={data ? "Update Lead" : "Add Lead"}
      centered
      width={900}
      visible={open}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          ...(data
            ? {
                ...data,
                bhk: data.bhk_info
                  ? {
                      value: data.bhk,
                      _id: data.bhk,
                      label: data.bhk_info.title,
                      key: data.bhk,
                    }
                  : null,
                project: data.project_info
                  ? {
                      ...data.project_info,
                      value: data.project,
                      _id: data.project,
                      label: data.project_info.title,
                      key: data.project,
                    }
                  : null,
                status: data.status
                  ? {
                      value: data.statusId,
                      _id: data.statusId,
                      label: data.status.status,
                      key: data.statusId,
                    }
                  : null,
                developer: data.developer_info
                  ? {
                      value: data.developer,
                      _id: data.developer,
                      label: data.developer_info.title,
                      key: data.developer,
                    }
                  : null,
                city: data.city_info
                  ? {
                      value: data.city,
                      _id: data.city,
                      label: data.city_info.city,
                      key: data.city,
                    }
                  : null,
                lead_source: data.lead_source_info
                  ? {
                      value: data.lead_source,
                      _id: data.lead_source,
                      label: data.lead_source_info.lead_source,
                      key: data.lead_source,
                    }
                  : null,
                priority: data.priority
                  ? {
                      value: data.priorityId,
                      _id: data.priorityId,
                      label: data.priority.priority,
                      key: data.priorityId,
                    }
                  : null,
                manager: data.manager
                  ? {
                      value: data.manager,
                      _id: data.manager,
                      label: data.manager_info.name,
                      key: data.manager,
                    }
                  : null,
                assignedTo: data.assignedTo
                  ? {
                      value: data.assignedTo,
                      _id: data.assignedTo,
                      label: data.assignedTo_info.name,
                      key: data.assignedTo,
                    }
                  : null,
              }
            : {}),
        }}
        onFinish={handleOnSave}
      >
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Name"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input Lead Name",
                },
              ]}
            >
              <Input placeholder="name" />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              label="Unit Type"
              tooltip="Choose unit type(Need to type atleast 3 character) "
              name="bhk"
              rules={[
                {
                  required: true,
                  message: "Please select unit type",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "100%" }}
                placeholder={"Search by title"}
                fetchOptions={async (text) => {
                  return getOptions(text, { type: "category" });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Contact 1"
              name="contact1"
              rules={[
                {
                  required: true,
                  message: "Please input Contact 1",
                },
              ]}
            >
              <Input placeholder="contact 1" />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item label="Contact 2" name="contact2">
              <Input placeholder="contact 2" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Email 1"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input Email1",
                },
              ]}
            >
              <Input placeholder="email 1" />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item label="Email 2" name="email2">
              <Input placeholder="email 2" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Query"
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Please input query",
                },
              ]}
            >
              <Input.TextArea
                style={{ height: 120 }}
                placeholder="type here..."
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item label="Lead Priority" name="priority">
              <SearchSelect
                style={{ width: "100%" }}
                placeholder={"Search by priority"}
                fetchOptions={async (text) => {
                  return getOptions(text, {
                    type: "priority",
                    valueKey: "priorityId",
                    labelKey: "priority",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              label="Lead Source"
              name="lead_source"
              rules={[
                {
                  required: true,
                  message: "Please select lead source",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "100%" }}
                placeholder={"Search by title"}
                fetchOptions={async (text) => {
                  return getOptions(text, {
                    type: "lead_source",
                    labelKey: "lead_source",
                  });
                }}
                showAdd={true}
                onAdd={handleSourceCreate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item label="City" name="city">
              <SearchSelect
                style={{ width: "100%" }}
                placeholder={"Search by city"}
                fetchOptions={async (text) => {
                  return getOptions(text, { type: "city", labelKey: "city" });
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "Please select status",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "100%" }}
                placeholder={"Search by status"}
                fetchOptions={async (text) => {
                  return getOptions(text, {
                    type: "status",
                    valueKey: "statusId",
                    labelKey: "status",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item label="Manager" name="manager">
              <SearchSelect
                style={{ width: "100%" }}
                placeholder={"Search by name"}
                fetchOptions={async (text) => {
                  return getOptions(text, {
                    type: "manager",
                    valueKey: "userId",
                    labelKey: "name",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item label="Relation Manager" name="assignedTo">
              <SearchSelect
                style={{ width: "100%" }}
                placeholder={"Search by name"}
                fetchOptions={async (text) => {
                  return getOptions(text, {
                    type: "user",
                    valueKey: "userId",
                    labelKey: "name",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Builder"
              name="developer"
              rules={[
                {
                  required: true,
                  message: "Please select Builder",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "100%" }}
                placeholder={"Search by title"}
                fetchOptions={async (text) => {
                  return getOptions(text, { type: "developer" });
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              label="Project"
              name="project"
              rules={[
                {
                  required: true,
                  message: "Please select Project",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "100%" }}
                placeholder={"Search by title"}
                options={projects}
                showSearch={false}
                // fetchOptions={async (text) => {
                //   return getOptions(text, { type: "project" });
                // }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {data ? "Update" : "Add"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default LeadModal;
