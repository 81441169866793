import { PlusOutlined } from "@ant-design/icons";
import { Button, message, Tooltip } from "antd";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { apiRequest } from "../../api";
import apiPaths from "../../api/paths";
import DynamicTable from "../../components/DynamicTable";
import DeveloperModal from "./components/DeveloperModal";

function Developers() {
  const [editingKey, setEditingKey] = useState(null);
  const [developers, setDevelopers] = useState({});
  const [showDeveloperModal, setShowDeveloperModal] = useState(false);
  const loadingRef = useRef(true);

  useEffect(() => {
    fetchDevelopers();
  }, []);

  const fetchDevelopers = async () => {
    const res = await apiRequest({
      path: apiPaths.developers,
    });
    loadingRef.current = false;
    if (!res.error) {
      let developersObj = res.results.reduce((developers, user) => {
        developers[user.id + "d"] = user;
        return developers;
      }, {});
      setDevelopers(developersObj);
    } else {
      message.error(res.error_description);
    }
  };

  const COLUMNS = [
    {
      title: "Name",
      dataIndex: "title",
      fixed: "left",
      width: 170,
      filterType: "search",
    },
    // {
    //   title: "Details",
    //   dataIndex: "details",
    //   width: "150px",
    //   filterType: "search",
    // },
    {
      title: "Status",
      dataIndex: "status",
      render: (value) => (value ? "Active" : "In Active"),
      // filterType: "search",
      width: "120px",
    },
    {
      title: "City",
      dataIndex: "city_info.city",
      width: "120px",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      width: "120px",
      filterType: "date",
    },
    {
      title: "Updated Date",
      dataIndex: "updatedDate",
      width: "120px",
      filterType: "date",
    },
  ];

  const handleRowCanceled = () => {
    setShowDeveloperModal(false);
  };

  const handleRowEdit = (idx) => {
    setEditingKey(idx);
    setShowDeveloperModal(true);
  };

  const handleRowSave = async (data) => {
    let updatedDevelopers = { ...developers };
    if (updatedDevelopers[data.id + "d"]) {
      updatedDevelopers[data.id + "d"] = data;
    } else {
      updatedDevelopers = { [data.id + "d"]: data, ...developers };
    }
    setDevelopers(updatedDevelopers);
    setEditingKey(null);
    setShowDeveloperModal(false);
  };

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 10,
          marginBottom: 10,
        }}
      >
        <Tooltip title="Add Developer">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setShowDeveloperModal(true);
            }}
          />
        </Tooltip>
      </div>
      <div style={{ height: "90%" }}>
        <DynamicTable
          loading={loadingRef.current}
          dataSource={Object.values(developers)}
          columns={COLUMNS}
          rowKey="id"
          scroll={{
            // x: 2250,
            y: "95%",
          }}
          pagination={false}
          editHandler={(record) => handleRowEdit(record.id + "d")}
        />
      </div>
      {showDeveloperModal ? (
        <DeveloperModal
          open={showDeveloperModal}
          onSave={handleRowSave}
          onCancel={handleRowCanceled}
          data={editingKey ? developers[editingKey] : null}
        />
      ) : null}
    </div>
  );
}

export default Developers;
