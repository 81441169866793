import { apiRequest } from "../api";
import apiPaths from "../api/paths";
import {
  REMOVE_USER_TOKEN_DATA,
  SET_USER_TOKEN_DATA,
  SET_SELF_USER_DATA,
  REMOVE_USER_DATA,
  SET_USER_NOTIFICATIONS,
  ADD_USER_NOTIFICATION,
  READ_USER_NOTIFICATION,
  SET_DASH_STATS,
  SET_CAMPAIGNS,
} from "./types";

export const setUserTokenData = (data) => ({
  type: SET_USER_TOKEN_DATA,
  data: data,
});

export const setUserNotifications = (data) => ({
  type: SET_USER_NOTIFICATIONS,
  data: data,
});

export const setSelfUserInfo = (data) => ({
  type: SET_SELF_USER_DATA,
  data: data,
});

export const addUserNotification = (data) => ({
  type: ADD_USER_NOTIFICATION,
  data: data,
});

export const removeUserTokenData = () => ({
  type: REMOVE_USER_TOKEN_DATA,
});

export const removeUserData = () => ({
  type: REMOVE_USER_DATA,
});

export const setUserNotificationsRead = (data) => ({
  type: READ_USER_NOTIFICATION,
  data: data,
});

export const logoutUser = () => {
  return (dispatch) => {
    dispatch(removeUserTokenData());
  };
};

export const setLoginUser = (data) => {
  return async (dispatch) => {
    let self = await dispatch(getSelfUser(data));
    if (self.error) {
      return self;
    }
    await dispatch(setUserTokenData(data));
  };
};

export const deleteUserData = () => {
  return (dispatch) => {
    dispatch(removeUserData());
  };
};

export const getSelfUser = (tokenData = null) => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.self,
      token: tokenData,
    }).then((res) => {
      if (!res.error) {
        dispatch(setSelfUserInfo(res));
      }
      return res;
    });
  };
};

export const getUserNotifications = () => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.notifications,
    }).then((res) => {
      if (!res.error) {
        dispatch(setUserNotifications(res.results));
        if (res.has_not_seen) {
          dispatch(setUserNotificationsRead(res.has_not_seen));
        }
      }
      return res;
    });
  };
};

export const markUserNotificationsRead = () => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.notifications + "/seen-all",
      method: "POST",
    }).then((res) => {
      if (!res.error) {
        dispatch(setUserNotificationsRead(0));
      }
      return res;
    });
  };
};

export const loginUser = (data) => {
  return (dispatch) => {
    return apiRequest({
      data: data,
      isLogin: true,
    }).then(async (res) => {
      if (!res.error && !res.is_two_step_enabled) {
        let self = await dispatch(getSelfUser(res));
        if (self.error) {
          return self;
        }
        dispatch(setUserTokenData(res));
      }
      return res;
    });
  };
};

export const verifyUser = (data) => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.verify,
      method: "POST",
      secured: false,
      data: data,
    }).then(async (res) => {
      if (!res.error) {
        await dispatch(setLoginUser(res));
      }
      return res;
    });
  };
};

export const getDashboardStats = () => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.stats,
    }).then(async (res) => {
      if (!res.error) {
        await dispatch({
          type: SET_DASH_STATS,
          data: res,
        });
      }
      return res;
    });
  };
};

export const getCampaigns = () => {
  return (dispatch) => {
    return apiRequest({
      path: apiPaths.campains,
    }).then(async (res) => {
      if (!res.error) {
        let campainsObj = res.results.reduce((campains, campain) => {
          campains[campain.id] = campain;
          return campains;
        }, {});
        await dispatch({
          type: SET_CAMPAIGNS,
          data: campainsObj,
        });
      }
      return res;
    });
  };
};
