import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { isFunction } from "lodash";
import React, { useContext } from "react";
import MultipleValueInput from "./MultiplevalueInput";
import SearchSelect from "./SearchSelect";
import UploadImage from "./UploadImage";
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  nonupdatable,
  options = [],
  children,
  required = true,
  placeholder,
  fetchOptions,
  valuePropName,
  renderInputNode,
  ...restProps
}) => {
  const form = useContext(EditableContext);
  let inputNode = <Input placeholder={placeholder} />;
  switch (inputType) {
    case "search-select": {
      inputNode = (
        <SearchSelect
          placeholder={placeholder}
          fetchOptions={fetchOptions}
          styles={{ width: "100%" }}
        />
      );
      break;
    }
    case "select": {
      inputNode = (
        <Select
          placeholder={placeholder}
          labelInValue={true}
          style={{ width: "100%" }}
        >
          {options.map((op, index) => (
            <Select.Option key={index} value={op.value}>
              {op.label}
            </Select.Option>
          ))}
        </Select>
      );

      break;
    }
    case "multiselect": {
      inputNode = (
        <Select mode="multiple" placeholder={placeholder} labelInValue={true}>
          {options.map((op, index) => (
            <Select.Option key={index} value={op.value}>
              {op.label}
            </Select.Option>
          ))}
        </Select>
      );
      break;
    }
    case "multiple-input": {
      inputNode = (
        <MultipleValueInput placeholder={placeholder} labelInValue={true} />
      );
      break;
    }
    case "number": {
      inputNode = (
        <InputNumber
          placeholder={placeholder}
          style={{ width: "100%" }}
          precision={2}
        />
      );
      break;
    }
    case "rangedate": {
      inputNode = <DatePicker.RangePicker />;
      break;
    }
    case "checkbox": {
      inputNode = <Checkbox style={{ marginLeft: 10 }} />;
      break;
    }
    case "date": {
      inputNode = (
        <DatePicker
          disabled={form.getFieldValue("depends_on")?.length > 0 ? true : false}
        />
      );
      break;
    }
    case "image": {
      inputNode = <UploadImage />;
      break;
    }
    default:
  }

  return (
    <td {...restProps}>
      {editing && !(nonupdatable && record[dataIndex]) ? (
        <Form.Item
          valuePropName={valuePropName}
          name={dataIndex}
          key={index + dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: isFunction(required)
                ? required({ ...record, ...form.getFieldsValue() })
                : required,
              message: `${title} is required!`,
            },
          ]}
        >
          {renderInputNode
            ? renderInputNode({ ...record, ...form.getFieldsValue() })
            : inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function EditableTable({
  data = [],
  scroll,
  onRowSave = () => {},
  onRowDelete = () => {},
  onRowCancel = () => {},
  onRowEdit = () => {},
  columns,
  editingKey,
  showOperations = true,
  deleteText = true,
  rowKey = "key",
  ...props
}) {
  const isEditing = (record) => record[rowKey] === editingKey;

  const edit = (record, form) => {
    form.setFieldsValue({
      ...record,
    });
    onRowEdit(record[rowKey], record);
  };

  const cancel = () => {
    onRowCancel();
  };

  const save = async (key, form) => {
    try {
      const row = await form.validateFields();
      onRowSave(key, row);
    } catch (e) {}
  };

  const ActionCell = ({ record, editable }) => {
    const form = useContext(EditableContext);
    return editable ? (
      <span>
        <Typography.Link
          onClick={() => save(record[rowKey], form)}
          style={{
            marginRight: 8,
          }}
        >
          Save
        </Typography.Link>
        <Popconfirm
          title="Sure, want to cancel?"
          cancelText="Not now"
          okText="Yes"
          onConfirm={cancel}
        >
          <a>Cancel</a>
        </Popconfirm>
      </span>
    ) : (
      <Space>
        <Typography.Link
          disabled={editingKey !== null}
          onClick={() => edit(record, form)}
        >
          Edit
        </Typography.Link>
        {deleteText && (
          <Popconfirm
            placement="topLeft"
            title="Are you sure, to delete this row?"
            okText="Yes"
            onConfirm={() => onRowDelete(record)}
          >
            <Typography.Link disabled={editingKey !== null}>
              Delete
            </Typography.Link>
          </Popconfirm>
        )}
      </Space>
    );
  };

  if (showOperations) {
    columns = [
      ...columns,
      {
        title: "Actions",
        dataIndex: "operation",
        actions: true,
        fixed: "right",
        width: 110,
        render: (_, record) => {
          let { isEditable = true } = record;
          const editable = isEditing(record);
          return isEditable ? (
            <ActionCell record={record} editable={editable} />
          ) : null;
        },
      },
    ];
  }
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.type,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: col.options,
        placeholder: col.placeholder,
        fetchOptions: col.fetchOptions,
        required: col.required,
        nonupdatable: col.nonupdatable,
        colSpan: col.colSpan,
        ...col,
      }),
    };
  });
  return (
    <Table
      components={{
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      }}
      scroll={scroll}
      bordered
      size="small"
      style={{ marginTop: 15, marginBottom: 15 }}
      rowKey={rowKey}
      dataSource={data}
      columns={mergedColumns}
      pagination={false}
      {...props}
    />
  );
}

export default EditableTable;
