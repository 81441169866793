import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Space,
} from "antd";
import { apiRequest } from "../../../api";
import apiPaths from "../../../api/paths";
import SearchSelect from "../../../components/SearchSelect";

function ProjectModal({
  open = false,
  onSave = () => {},
  onCancel = () => {},
  data,
}) {
  const [form] = Form.useForm();

  const getOptions = async (searchText, optionObj = {}) => {
    const { type, valueKey = "id", labelKey = "title" } = optionObj;
    let url = "";
    switch (type) {
      case "city": {
        url = apiPaths.cities + `?city_contains=${searchText}`;
        break;
      }
      case "developer": {
        url = apiPaths.developers + `?title_contains=${searchText}`;
        break;
      }
      default: {
        return [];
      }
    }
    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    return options.map((item, index) => ({
      ...item,
      key: index,
      value: item[valueKey],
      _id: item[valueKey],
      label: `${item[labelKey]}`,
    }));
  };

  const handleSave = async (values) => {
    if (!values) {
      return;
    }
    values.city = values.city.value;
    values.developer = values.developer.value;
    let bodyObj = {
      path: apiPaths.projects,
      method: "POST",
      data: values,
    };
    if (data) {
      bodyObj = {
        path: apiPaths.projects + `/${data.id}`,
        method: "PUT",
        data: values,
      };
    }
    let res = await apiRequest(bodyObj);
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    message.success(`Project ${data ? "updated" : "added"} successfully`);
    onSave(res);
  };

  return (
    <Modal
      title={`${data ? "Update" : "Add"} Project`}
      centered
      width={900}
      visible={open}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={handleSave}
        form={form}
        initialValues={
          data
            ? {
                ...data,
                city: data.city
                  ? {
                      value: data.city,
                      label: data.city_info.city,
                      key: data.city_info.id,
                      ...data.city_info,
                    }
                  : null,
                developer: data.developer
                  ? {
                      value: data.developer,
                      label: data.developer_info.title,
                      key: data.developer_info.id,
                      ...data.developer_info,
                    }
                  : null,
              }
            : { status: 1 }
        }
      >
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input title",
                },
              ]}
            >
              <Input placeholder="project title" />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              label="Locality"
              name="details"
              rules={[
                {
                  required: true,
                  message: "Please input locality",
                },
              ]}
            >
              <Input placeholder="project locality" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="City"
              rules={[
                {
                  required: true,
                  message: "Please select city",
                },
              ]}
              name="city"
            >
              <SearchSelect
                style={{ width: "310px" }}
                placeholder={"Search by city"}
                fetchOptions={async (text) => {
                  return getOptions(text, { type: "city", labelKey: "city" });
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              label="Developer"
              rules={[
                {
                  required: true,
                  message: "Please select developer",
                },
              ]}
              name="developer"
            >
              <SearchSelect
                style={{ width: "310px" }}
                placeholder={"Search by developer"}
                fetchOptions={async (text) => {
                  return getOptions(text, {
                    type: "developer",
                    labelKey: "title",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item label="Status" name="status">
              <Radio.Group>
                <Radio value={1}> Active </Radio>
                <Radio value={0}> In Active </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              {"Save"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ProjectModal;
