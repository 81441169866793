import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../actions/user";
import PrivatePages from "./Private";

function Roles() {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <div className="app flex">
      {/* {userData.department && userData.department !== "" ? ( */}
      <PrivatePages />
      {/* ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 16, marginBottom: 10 }}>
            You dont have a role assigned, Please contact Admin.
          </div>
          <Button onClick={handleLogout}>Logout</Button>
        </div>
      )} */}
    </div>
  );
}

export default Roles;
