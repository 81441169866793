import {
  SET_LEADS,
  ADD_LEAD,
  UPDATE_LEAD_DATA,
  SET_BOOKINGS,
  UPDATE_LEADS_DATA,
  UPDATE_BOOKING,
  DELETE_LEADS,
  SET_LEADS_FILTERS,
  SET_LEADS_PAGINATION,
} from "../actions/types";

const initialState = {
  leads: [],
  bookings: [],
  leadsIndex: {},
  filters: {},
  leadsPagination: {},
};

const leadReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEADS: {
      let leadsIndex = action.data.reduce((obj, lead, idx) => {
        obj[lead.id] = idx;
        return obj;
      }, {});
      return {
        ...state,
        leads: action.data,
        leadsIndex,
      };
    }
    case SET_BOOKINGS: {
      return {
        ...state,
        bookings: action.data,
      };
    }
    case SET_LEADS_PAGINATION: {
      return {
        ...state,
        leadsPagination: action.data,
      };
    }
    case SET_LEADS_FILTERS: {
      return {
        ...state,
        filters: action.data,
      };
    }
    case DELETE_LEADS: {
      let deleteIds = action.data.lead_ids.reduce((obj, id) => {
        obj[id] = id;
        return obj;
      }, {});
      let updatedLeads = state.leads.map((lead) => {
        if (deleteIds[lead.id]) {
          lead.is_deleted = true;
        }
        return lead;
      });
      return {
        ...state,
        leads: updatedLeads,
      };
    }
    case UPDATE_BOOKING: {
      let updatedBookings = [...state.bookings];
      let bookingIndx = updatedBookings.findIndex(
        (booking) => booking.id == action.data.id
      );
      if (bookingIndx >= 0) {
        updatedBookings[bookingIndx] = {
          ...updatedBookings[bookingIndx],
          ...action.data,
        };
      }
      return {
        ...state,
        bookings: updatedBookings,
      };
    }
    case ADD_LEAD: {
      let updatedLeads = [action.data, ...state.leads];
      let leadsIndex = updatedLeads.reduce((obj, lead, idx) => {
        obj[lead.id] = idx;
        return obj;
      }, {});
      return {
        ...state,
        leads: updatedLeads,
        leadsIndex,
      };
    }
    case UPDATE_LEAD_DATA: {
      let updatedLeads = [...state.leads];
      let leadIndx = updatedLeads.findIndex(
        (lead) => lead.id == action.data.id
      );
      if (leadIndx >= 0) {
        updatedLeads[leadIndx] = {
          ...updatedLeads[leadIndx],
          ...action.data,
        };
      }
      return {
        ...state,
        leads: updatedLeads,
      };
    }
    case UPDATE_LEADS_DATA: {
      let updatedLeads = [...state.leads];
      action.data.map((lead) => {
        let leadIndex = state.leadsIndex[lead.id];
        if (leadIndex != undefined) {
          updatedLeads[leadIndex] = lead;
        }
      });
      return {
        ...state,
        leads: updatedLeads,
      };
    }
    default:
      return state;
  }
};

export default leadReducer;
