import { logoutUser, setUserTokenData } from "../actions/user";
import { logError } from "../common";
import { API_ROOT_URL, CLIENT_ID, CLIENT_SECRET } from "../config";
import { store } from "../store";
import apiPaths from "./paths";

const isTokenExpired = (tokenData) => {
  let currentUnixDate = parseInt(new Date().getTime() / 1000);
  if (
    tokenData.expiry_date &&
    tokenData.expiry_date - currentUnixDate < 86400
  ) {
    return tokenData.refresh_token;
  } else {
    return false;
  }
};

const refreshUserToken = async (refreshToken) => {
  let data = {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  };
  const responseData = await fetchWithoutToken(apiPaths.token, "POST", data);
  if (responseData.error) {
    return false;
  }
  responseData["expiry_date"] =
    parseInt(new Date().getTime() / 1000) + responseData.expires_in;
  return responseData;
};

const getUserToken = async (userCredentials) => {
  let data = {
    grant_type: "password",
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    ...userCredentials,
  };
  const responseData = await fetchWithoutToken(apiPaths.token, "POST", data);
  if (responseData.error) {
    return responseData;
  }
  responseData["expiry_date"] =
    parseInt(new Date().getTime() / 1000) + responseData.expires_in;
  return responseData;
};

export const apiRequest = async ({
  path,
  method = "GET",
  data = null,
  formData = null,
  isLogin,
  token = null,
  secured = true,
  file = false,
}) => {
  try {
    let state = store.getState();
    let tokenData = token || state.token.tokenData;
    let accessToken = null;
    if (isLogin) {
      return getUserToken(data);
    }
    if (secured) {
      if (!tokenData) {
        return;
      }
      let refreshToken = isTokenExpired(tokenData);
      if (refreshToken) {
        const refreshedTokenData = await refreshUserToken(refreshToken);
        if (!refreshedTokenData) {
          let error = {
            error: "Client errors",
            error_description: "Your token expired",
          };
          store.dispatch(logoutUser());
          return { error: error };
        }
        store.dispatch(setUserTokenData(refreshedTokenData));
        accessToken = refreshedTokenData.access_token;
      } else {
        accessToken = tokenData.access_token;
      }
    }

    const url = `${API_ROOT_URL}${path}`;
    const options = {
      method: method,
      headers: {
        Accept: "application/json",
      },
      body: data ? JSON.stringify(data) : formData,
    };
    if (!formData) {
      options.headers["Content-Type"] = "application/json";
    }
    if (accessToken) {
      options.headers.Authorization = "Bearer " + accessToken;
    }
    const response = await fetch(url, options);
    if (response.status >= 200 && response.status <= 299) {
      if (file) {
        return response.blob();
      }
      const responseData = await response.json();
      return responseData;
    } else if (response.status >= 400 && response.status <= 499) {
      const responseData = await response.json();
      responseData["status"] = response.status;
      if (
        response.status == 401 ||
        responseData.error_description == "Token not found or expired"
      ) {
        store.dispatch(logoutUser());
      }
      logError(responseData);
      return responseData;
    } else if (response.status >= 500 && response.status <= 599) {
      let message = {
        error: "Server errors",
        error_description: "Server status 500",
      };
      message["status"] = response.status;
      message["error_description"] =
        "Ooops...!, Something went wrong please try after sometimes.";
      logError(response);
      return message;
    }
  } catch (e) {
    logError(e);
    let message = {
      error: "Network Error",
      error_description: `Not able to connect with the server`,
    };
    return message;
  }
};

export const fetchWithoutToken = async (path, method = "GET", data = null) => {
  try {
    const url = `${API_ROOT_URL}${path}`;
    const options = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: data ? JSON.stringify(data) : data,
    };
    const response = await fetch(url, options);
    const responseData = await response.json();
    if (responseData?.error === "invalid_grant") {
      return {
        error: "Invalid Login Details",
        error_description:
          "Please check, your username and password is correct.",
      };
    }
    logError(responseData);
    return responseData;
  } catch (e) {
    logError(e);
    let message = {
      error: "Network Error",
      error_description: `Not able to connect with the server`,
    };
    return message;
  }
};
