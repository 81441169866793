import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, message, Popconfirm, Space, Table } from "antd";
import SearchSelect from "../../../components/SearchSelect";
import apiPaths from "../../../api/paths";
import { apiRequest } from "../../../api";
function Relations({ userId, ...props }) {
  const [relations, setRelations] = useState(props.relations || []);
  const [projects, setProjects] = useState(false);
  const [form] = Form.useForm();
  const developerValue = Form.useWatch("developer", form);
  const projectValue = Form.useWatch("project", form);

  useEffect(() => {
    if (developerValue) {
      getProjects(developerValue.value);
      if (projectValue?.developer != developerValue.value) {
        form.setFieldValue("project", undefined);
      }
    }
  }, [developerValue]);

  const getOptions = async (searchText, optionObj = {}) => {
    const { type, valueKey = "id", labelKey = "title" } = optionObj;
    let url = "";
    switch (type) {
      case "developer": {
        url = apiPaths.developers + `?title_contains=${searchText}`;
        break;
      }
      case "project": {
        url = apiPaths.projects + `?title_contains=${searchText}`;
        break;
      }
      default: {
        return [];
      }
    }
    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    return options.map((item, index) => ({
      ...item,
      key: index,
      value: item[valueKey],
      _id: item[valueKey],
      label: `${item[labelKey]}`,
    }));
  };

  const getProjects = async (developer) => {
    let url = apiPaths.projects + `?developer=${developer}`;
    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    options = options.map((item, index) => ({
      ...item,
      key: index,
      value: item.id,
      _id: item.id,
      label: `${item.title}`,
    }));
    setProjects(options);
  };

  const handleSave = async (values) => {
    let data = {
      projectId: values.project.value,
      developerId: values.developer.value,
    };

    const res = await apiRequest({
      path: apiPaths.users + `/${userId}/relation`,
      method: "POST",
      data: data,
    });
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    message.success("User relation added successfully");
    form.resetFields();
    setRelations([...relations, { ...values, ...res }]);
  };

  const handleDelete = async (rId) => {
    const res = await apiRequest({
      path: apiPaths.users + `/${userId}/relation/${rId}`,
      method: "DELETE",
    });
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    message.success("User relation removed successfully");
    let updated = relations.filter((item) => item.id != rId);
    setRelations([...updated]);
  };

  return (
    <div style={{ padding: 20 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>Relations</div>
        <Form layout="horizontal" form={form} onFinish={handleSave}>
          <Space>
            <Form.Item
              tooltip="Choose Developer(Need to type atleast 3 character) "
              name="developer"
              rules={[
                {
                  required: true,
                  message: "Please select developer",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "310px" }}
                placeholder={"Search by developer title"}
                fetchOptions={async (text) => {
                  return getOptions(text, { type: "developer" });
                }}
              />
            </Form.Item>
            <Form.Item
              tooltip="Choose Project (Need to type atleast 3 character) "
              name="project"
              rules={[
                {
                  required: true,
                  message: "Please select project",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "310px" }}
                placeholder={"Search by project title"}
                options={projects}
                showSearch={false}

                // fetchOptions={async (text) => {
                //   return getOptions(text, { type: "project" });
                // }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {"+ Add Relation"}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </div>
      <Table
        columns={[
          {
            title: "Developer",
            dataIndex: "developer",
            width: 150,
            render: (_, record) => record.developer?.title,
          },
          {
            title: "Project",
            dataIndex: "project",
            width: 150,
            render: (_, record) => record.project?.title,
          },
          {
            title: "",
            dataIndex: "id",
            width: 10,
            render: (_, record) => (
              <Popconfirm
                title="Are you sure to delete this relation?"
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                  <DeleteOutlined style={{ fontSize: 20 }} />
                </Button>
              </Popconfirm>
            ),
          },
        ]}
        pagination={false}
        dataSource={relations}
      />
    </div>
  );
}
export default Relations;
