import { Button, Typography, Badge, Card } from "antd";
import { useEffect, useRef } from "react";
import { BellOutlined, CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserNotifications,
  markUserNotificationsRead,
} from "../actions/user";
import moment from "moment";

function Notifications({ style = {} }) {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.token.notifications) || [];
  const notificationNotseen = useSelector(
    (state) => state.token.notificationsNotSeen
  );
  const notifContainerRef = useRef();

  useEffect(() => {
    dispatch(getUserNotifications());
  }, []);

  const handleCloseNotif = (e) => {
    e.stopPropagation();
    notifContainerRef.current.style.display = "none";
  };

  const renderNotification = (content = {}) => {
    switch (content.type) {
      case "new_lead": {
        return (
          <>
            <div
              style={{
                fontWeight: "bold",
              }}
            >
              {content?.content?.message}
            </div>
            <div>{`(${content?.content?.name})`}</div>
          </>
        );
      }
      default:
        return (
          <>
            <div
              style={{
                fontWeight: "bold",
              }}
            >
              {content?.content?.message}
            </div>
            <div>{`(${content?.content?.name})`}</div>
          </>
        );
    }
  };

  return (
    <>
      <Button
        size="small"
        style={style}
        onClick={(e) => {
          notifContainerRef.current.style.display = "block";
          if (notificationNotseen) {
            dispatch(markUserNotificationsRead());
          }
        }}
        type="text"
      >
        <Badge count={notificationNotseen}>
          <BellOutlined style={{ fontSize: 25, color: "#fff" }} />
        </Badge>
      </Button>
      <div
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          height: "100vh",
          width: "100vw",
          display: "none",
          backgroundColor: "#00000050",
          zIndex: 99999,
        }}
        ref={(ref) => (notifContainerRef.current = ref)}
        onClick={handleCloseNotif}
      >
        <div
          style={{
            // width: "30vw",
            // width: 300,
            position: "absolute",
            right: 0,
            backgroundColor: "#fff",
            height: "100vh",
            zIndex: 1,
            padding: "18px 18px",
            display: "flex",
            flexDirection: "column",
          }}
          className="notif"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Typography.Title level={3}>Notifications</Typography.Title>
          <Button
            size="small"
            type="text"
            onClick={handleCloseNotif}
            style={{ position: "absolute", right: 25, top: 15 }}
          >
            <CloseOutlined style={{ fontSize: 18 }} />
          </Button>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              width: "100%",
              position: "relative",
              overflow: "auto",
            }}
          >
            {notifications.map((notif, idx) => (
              <Card
                size="small"
                style={{
                  width: "100%",
                  border: "0.5px solid #b7b7b7",
                  marginBottom: 5,
                }}
                key={idx}
              >
                {renderNotification(notif.content)}
                <div
                  style={{
                    color: "#a7a7a7",
                    fontSize: 12,
                    position: "absolute",
                    right: 5,
                    bottom: 5,
                  }}
                >
                  {moment(notif.createdDate).fromNow()}
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Notifications;
