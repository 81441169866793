import React, { useState } from "react";
import { Avatar, Button, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../actions/user";
import { UserOutlined } from "@ant-design/icons";

const Profile = ({ userData, showName = true, size = "large" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/");
  };

  return (
    <Popover
      content={
        <div>
          <a onClick={handleLogout}>Logout</a>
        </div>
      }
      title={userData.email}
      trigger="click"
      open={open}
      showArrow={false}
      onOpenChange={handleOpenChange}
    >
      <Avatar icon={<UserOutlined />} size={size} />
      {showName ? (
        <div style={{ color: "#fff", paddingTop: 10 }}>{userData.name}</div>
      ) : null}
      {/* <Button type="primary">Click me</Button> */}
    </Popover>
  );
};
export default Profile;
