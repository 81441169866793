import { Button, message, Popconfirm } from "antd";
import React, { useState } from "react";
import { apiRequest } from "../../api";
import apiPaths from "../../api/paths";
import DragUpload from "../../components/DragUpload";
import EditableTable from "../../components/EditableTable";

function UploadLeads() {
  const [editingKey, setEditingKey] = useState(null);
  const [items, setItems] = useState([]);
  const [itemErrors, setItemErrors] = useState({});

  const getOptions = async (searchText, optionObj = {}) => {
    const { type, valueKey = "id", labelKey = "title" } = optionObj;
    let url = "";
    switch (type) {
      case "developer": {
        url = apiPaths.developers + `?title_contains=${searchText}`;
        break;
      }
      case "category": {
        url = apiPaths.categories + `?title_contains=${searchText}`;
        break;
      }
      case "status": {
        url = apiPaths.statuses + `?status_contains=${searchText}`;
        break;
      }
      case "city": {
        url = apiPaths.cities + `?city_contains=${searchText}`;
        break;
      }
      case "lead_source": {
        url = apiPaths.leadSources + `?lead_source_contains=${searchText}`;
        break;
      }
      case "project": {
        url = apiPaths.projects + `?title_contains=${searchText}`;
        break;
      }
      default: {
        return [];
      }
    }
    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    return options.map((item, index) => ({
      ...item,
      key: index,
      value: item[valueKey],
      _id: item[valueKey],
      label: `${item[labelKey]}`,
    }));
  };

  const renderCell = ({ record, dataIndex, formate }) => {
    const error =
      itemErrors[record.idx] && itemErrors[record.idx].errors[dataIndex];
    return (
      <div>
        {error ? (
          <div
            style={{
              position: "absolute",
              height: 6,
              width: 6,
              borderRadius: 5,
              backgroundColor: "red",
              left: 5,
              top: 5,
            }}
          />
        ) : null}
        <div>{formate ? formate(record) : record[dataIndex]}</div>
        {error ? (
          <div style={{ color: "red", fontSize: 9 }}>{error.name}</div>
        ) : null}
      </div>
    );
  };

  let ITEMCOLUMNS = [
    {
      title: "Id",
      dataIndex: "idx",
      editable: false,
      fixed: "left",
      width: 100,
      render: (value, record) => (
        <div>
          {itemErrors[record.idx] ? (
            <div
              style={{
                position: "absolute",
                height: 6,
                width: 6,
                borderRadius: 5,
                backgroundColor: "red",
                left: 5,
                top: 5,
              }}
            />
          ) : null}
          <div>{value}</div>
          {itemErrors[record.idx] ? (
            <div style={{ color: "red", fontSize: 9 }}>{"row has errors"}</div>
          ) : null}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "title",
      fixed: "left",
      editable: true,
      required: true,
      width: 150,
      type: "string",
    },
    {
      title: "Category",
      dataIndex: "bhk",
      editable: true,
      width: 150,
      type: "search-select",
      fetchOptions: async (text) => {
        return getOptions(text, {
          type: "category",
        });
      },
      formate: (record) => record.bhk?.label || record.bhk,
    },
    {
      title: "Contact 1",
      dataIndex: "contact1",
      editable: true,
      width: 120,
      type: "string",
    },
    {
      title: "Contact 2",
      dataIndex: "contact2",
      editable: true,
      required: false,
      width: 120,
      type: "string",
    },
    {
      title: "Email 1",
      dataIndex: "email",
      editable: true,
      required: false,
      width: 120,
      type: "string",
    },
    {
      title: "Email 2",
      dataIndex: "email2",
      editable: true,
      required: false,
      width: 120,
      type: "string",
    },
    {
      title: "Lead Source",
      dataIndex: "lead_source",
      editable: true,
      width: 150,
      type: "search-select",
      fetchOptions: async (text) => {
        return getOptions(text, {
          type: "lead_source",
          labelKey: "lead_source",
        });
      },
      formate: (record) => record.lead_source?.label || record.lead_source,
    },
    {
      title: "Status",
      dataIndex: "status",
      editable: true,
      width: 150,
      type: "search-select",
      fetchOptions: async (text) => {
        return getOptions(text, {
          type: "status",
          valueKey: "statusId",
          labelKey: "status",
        });
      },
      formate: (record) => record.status?.label || record.status,
    },
    {
      title: "Developer",
      dataIndex: "developer",
      editable: true,
      width: 150,
      type: "search-select",
      fetchOptions: async (text) => {
        return getOptions(text, {
          type: "developer",
        });
      },
      formate: (record) => record.developer?.label || record.developer,
    },
    {
      title: "Project",
      dataIndex: "project",
      editable: true,
      width: 150,
      type: "search-select",
      fetchOptions: async (text) => {
        return getOptions(text, {
          type: "project",
        });
      },
      formate: (record) => record.project?.label || record.project,
    },
    {
      title: "City",
      dataIndex: "city",
      editable: true,
      type: "search-select",
      fetchOptions: async (text) => {
        return getOptions(text, {
          type: "city",
          labelKey: "city",
        });
      },
      width: 120,
      formate: (record) => record.city?.label || record.city,
    },
    {
      title: "Desctiption",
      dataIndex: "comment",
      editable: true,
      width: 220,
    },
  ];

  ITEMCOLUMNS = ITEMCOLUMNS.map((column) => ({
    render: (_, record) => renderCell({ ...column, record }),
    ...column,
  }));

  const handleRowCanceled = () => {
    if (
      editingKey === items[items.length - 1].key &&
      !items[items.length - 1].rfq_quantity
    ) {
      let updatedItems = items.slice(0, -1);
      setItems([...updatedItems]);
    }
    setEditingKey(null);
  };

  const handleRowSave = (key, row) => {
    const itemUpdated = [...items];
    const index = itemUpdated.findIndex((item) => item.idx === key);
    const updatedItem = { ...itemUpdated[index], ...row };
    itemUpdated.splice(index, 1, updatedItem);
    delete itemErrors[updatedItem.idx];
    setItemErrors({ ...itemErrors });
    setItems(itemUpdated);
    setEditingKey(null);
  };

  const handleRowEdit = (key, record) => {
    setEditingKey(key);
  };

  const handleRowDelete = (row) => {
    let updatedItems = items.filter((item) => item.idx !== row.idx);
    setItems(updatedItems);
    delete itemErrors[row.idx];
  };

  const handleFileData = async (data) => {
    if (!data.length) {
      message.error("Atleast one item is required");
      return;
    }
    const res = await apiRequest({
      path: apiPaths.leads + "/upload",
      method: "POST",
      data,
    });
    setItems(res.data);
    setItemErrors(res.errors);
  };

  const handleLeadsSubmit = async (items) => {
    const res = await apiRequest({
      path: apiPaths.leads + "/upload-leads",
      method: "POST",
      data: items,
    });
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    message.success("Leads created successfully");
    setItems([]);
  };

  return (
    <div style={{ width: "100%" }}>
      <EditableTable
        data={items}
        editingKey={editingKey}
        columns={ITEMCOLUMNS}
        onRowCancel={handleRowCanceled}
        onRowSave={handleRowSave}
        rowKey="idx"
        onRowEdit={handleRowEdit}
        scroll={{ y: "50vh" }}
        onRowDelete={handleRowDelete}
      />
      {items.length ? (
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}
        >
          <Popconfirm
            placement="topLeft"
            title="Are you sure, to clear the table?"
            okText="Yes"
            onConfirm={() => {
              setEditingKey(null);
              setItemErrors({});
              setItems([]);
            }}
          >
            <Button style={{ marginRight: 15 }}>Clear</Button>
          </Popconfirm>
          <Popconfirm
            placement="topLeft"
            title="Are you sure, to submit leads?"
            okText="Yes"
            onConfirm={() => handleLeadsSubmit(items)}
          >
            <Button
              disabled={editingKey || Object.keys(itemErrors).length}
              type="primary"
            >
              Submit
            </Button>
          </Popconfirm>
        </div>
      ) : (
        <DragUpload onFileData={handleFileData} template={"leads"} />
      )}
    </div>
  );
}

export default UploadLeads;
