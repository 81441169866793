import { Layout, Menu, Breadcrumb, Typography } from "antd";
import { useState, useEffect, useMemo } from "react";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSelfUser } from "../actions/user";
import { APP_VERSION } from "../config";
import classNames from "../styles/styles.module.css";
import Leads from "./Leads/Leads";
import Users from "./Users/Users";
import images from "../assets/images";
import UploadLeads from "./UploadLeads/UploadLeads";
import Dashboard from "./Dashboard/Dashboard";
import Email from "./Email/Email";
import BookedLeads from "./BookedLeads/BookedLeads";
import Notifications from "../components/Notifications";
import Activities from "./Activities/Activities";
import Campains from "./Campains/Campains";
import Developers from "./Developers/Developers";
import Projects from "./Projects/Projects";
import Profile from "./Profile";
import useMobileOrDesktop from "../hooks/useMobileOrDesktop";
import { getLeadsFilters } from "../actions/lead";

const { Header, Sider, Content } = Layout;

function PrivatePages() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.token.userData);
  let isAdmin = userData.roleId == 1;
  const isMobile = useMobileOrDesktop();

  const toggle = (e) => {
    e.stopPropagation();
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    dispatch(getSelfUser());
    dispatch(getLeadsFilters());
  }, []);

  const componentLists = {
    Dashboard: {
      tabName: "Dashboard",
      tabIcon: (
        <img src={"/assets/imgs/stats.png"} style={{ width: 25, height: 25 }} />
      ),
      path: "/",
      component: <Dashboard />,
      subPaths: [],
    },
    Activities: {
      tabName: "Activities",
      tabIcon: (
        <img
          src={"/assets/imgs/activity.png"}
          style={{ width: 25, height: 25 }}
        />
      ),
      path: "/activities",
      component: <Activities />,
      subPaths: [],
    },
    Leads: {
      tabName: "Leads",
      tabIcon: (
        <img src={"/assets/imgs/leads.png"} style={{ width: 25, height: 25 }} />
      ),
      path: "/leads",
      component: <Leads />,
      subPaths: [],
    },
    Campaigns: {
      tabName: "Campaigns",
      tabIcon: (
        <img src={"/assets/imgs/link.png"} style={{ width: 25, height: 25 }} />
      ),
      path: "/campaigns",
      component: <Campains />,
      subPaths: [],
    },
    "Upload Leads": {
      tabName: "Upload Leads",
      tabIcon: (
        <img
          src={"/assets/imgs/upload.png"}
          style={{ width: 25, height: 25 }}
        />
      ),
      path: "/upload-leads",
      component: <UploadLeads />,
      subPaths: [],
    },
    Users: {
      tabName: "Users",
      tabIcon: (
        <img src={"/assets/imgs/users.png"} style={{ width: 25, height: 25 }} />
      ),
      path: "/users",
      component: <Users />,
      subPaths: [],
    },
    Developers: {
      tabName: "Developers",
      tabIcon: (
        <img
          src={"/assets/imgs/contractor.png"}
          style={{ width: 25, height: 25 }}
        />
      ),
      path: "/developers",
      component: <Developers />,
      subPaths: [],
    },
    Projects: {
      tabName: "Projects",
      tabIcon: (
        <img
          src={"/assets/imgs/building.png"}
          style={{ width: 25, height: 25 }}
        />
      ),
      path: "/projects",
      component: <Projects />,
      subPaths: [],
    },
    "Send Email": {
      tabName: "Send Email",
      tabIcon: (
        <img src={"/assets/imgs/mail.png"} style={{ width: 25, height: 25 }} />
      ),
      path: "/send-email",
      component: <Email />,
      subPaths: [],
    },
    "Booked Leads": {
      tabName: "Booked Leads",
      tabIcon: (
        <img
          src={"/assets/imgs/leads-booking.png"}
          style={{ width: 25, height: 25 }}
        />
      ),
      path: "/booked-leads",
      component: <BookedLeads />,
      subPaths: [],
    },
  };

  const Tabs = useMemo(() => {
    if (!userData) {
      return [];
    }
    if (userData.email == "marketing@propeve.com") {
      return [
        {
          component: "Campaigns",
        },
        { component: "Developers" },
        { component: "Projects" },
      ];
    }
    let tabs = [
      {
        component: "Dashboard",
      },
      {
        component: "Leads",
      },
      {
        component: "Upload Leads",
      },
      {
        component: "Send Email",
      },
      {
        component: "Booked Leads",
      },
    ];
    if (isAdmin) {
      tabs.push(
        ...[
          {
            component: "Campaigns",
          },
          { component: "Developers" },
          { component: "Projects" },
          {
            component: "Users",
          },
        ]
      );
    }
    if ([1, 2].includes(userData.roleId))
      tabs.push(
        ...[
          {
            component: "Activities",
          },
        ]
      );
    return tabs;
  }, userData);

  const renderTabButton = ({ component }, collapsed) => {
    let { tabName, tabIcon, path } = componentLists[component];
    const activePath = window.location.pathname.split("/", 2).join("/");
    return (
      <div
        key={path}
        onClick={() => {
          navigate(path);
        }}
        style={{
          marginBottom: 10,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          display: "flex",
          padding: "10px 12px",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: 14,
          cursor: "pointer",
          color: activePath == path ? "#000" : "#fff",
          background:
            activePath == path
              ? "linear-gradient(to right,#AAEAE850,#AAEAE8)"
              : "transparent",
        }}
      >
        <div>{tabIcon}</div>
        {collapsed ? null : <div style={{ marginLeft: 10 }}>{tabName}</div>}
      </div>
    );
  };

  const renderRoute = ({ path, component, subPaths = [] }) => {
    return (
      <Route key={path} path={path}>
        <Route index element={component} />
        {subPaths?.map((path) => renderRoute(path))}
      </Route>
    );
  };

  return (
    <Layout
      style={{ flex: 1, height: "100%" }}
      onClick={() => {
        setCollapsed(false);
      }}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={classNames.layoutSlider}
        style={{ backgroundColor: "#7148AA" }}
      >
        <div
          style={{
            display: "flex",
            padding: "20px 25px",
            width: "100%",
            paddingRight: 20,
            justifyContent: "space-between",
          }}
        >
          <Profile userData={userData} />
          <Notifications />
        </div>
        {/* <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            paddingBottom: 10,
            paddingTop: 10,
            backgroundColor: "#fff",
            overflow: "hidden",
          }}
        >
          <img src={images.logo} style={{ height: 40 }} />
        </div> */}
        {/* <div style={{ color: "#fff", marginTop: 10, marginLeft: 5 }}>
          {userData.email}
        </div> */}
        <div
          style={{
            paddingRight: 10,
            // marginTop: 25,
          }}
        >
          {Tabs.map((tab) => renderTabButton(tab, collapsed))}
        </div>
        <div style={{ position: "absolute", bottom: 10, right: 10 }}>
          <Typography.Text style={{ color: "#011E3D" }} strong>
            {"v" + APP_VERSION}
          </Typography.Text>
        </div>
      </Sider>
      {collapsed ? (
        <div
          style={{
            position: "absolute",
            zIndex: 99,
            top: 60,
          }}
          className={classNames.mobileTabs}
        >
          <Menu
            style={{
              width: 250,
              padding: "25px 30px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              backgroundColor: "#7148AA",
            }}
            selectedKeys={[window.location.pathname.split("/", 2).join("/")]}
          >
            {Tabs.map((tab) => renderTabButton(tab, !collapsed))}
          </Menu>
        </div>
      ) : null}
      <Layout className="site-layout">
        {isMobile ? (
          <Header
            className="site-layout-background site- flex"
            style={{
              padding: "0px 0px",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#54A6D3", //"#8A61F2",
              color: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {collapsed ? (
                <MenuUnfoldOutlined className="trigger" onClick={toggle} />
              ) : (
                <MenuFoldOutlined className="trigger" onClick={toggle} />
              )}
              <Breadcrumb>
                {window.location.pathname.split("/").map((path, index) => (
                  <Breadcrumb.Item style={{ color: "#fff" }} key={index + "b"}>
                    {path.toUpperCase()}
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 20,
              }}
            >
              <Notifications style={{ marginRight: 10 }} />
              <Profile size="small" userData={userData} showName={false} />
            </div>
          </Header>
        ) : null}

        <Content className="site-layout-background" style={{ height: "90vh" }}>
          <Routes>
            <Route
              path="/login"
              caseSensitive
              element={
                <Navigate to={componentLists[Tabs[0].component].path} replace />
              }
            />
            <Route path="/" caseSensitive />
            {Tabs.map((tab) => renderRoute(componentLists[tab.component]))}
            <Route path="*" element={<div>404</div>} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
}

export default PrivatePages;
