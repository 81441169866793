import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Space,
} from "antd";
import { useState } from "react";
import { apiRequest } from "../../../api";
import apiPaths from "../../../api/paths";
import SearchSelect from "../../../components/SearchSelect";

function DeveloperModal({
  open = false,
  onSave = () => {},
  onCancel = () => {},
  data,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const getOptions = async (searchText, optionObj = {}) => {
    const { type, valueKey = "id", labelKey = "title" } = optionObj;
    let url = "";
    switch (type) {
      case "city": {
        url = apiPaths.cities + `?city_contains=${searchText}`;
        break;
      }
      default: {
        return [];
      }
    }
    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    return options.map((item, index) => ({
      ...item,
      key: index,
      value: item[valueKey],
      _id: item[valueKey],
      label: `${item[labelKey]}`,
    }));
  };

  const handleSave = async (values) => {
    if (!values) {
      return;
    }
    setLoading(true);
    values.city = values.city.value;
    let bodyObj = {
      path: apiPaths.developers,
      method: "POST",
      data: values,
    };
    if (data) {
      bodyObj = {
        path: apiPaths.developers + `/${data.id}`,
        method: "PUT",
        data: values,
      };
    }
    let res = await apiRequest(bodyObj);
    setLoading(false);
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    message.success(`Developer ${data ? "updated" : "added"} successfully`);
    onSave(res);
  };

  return (
    <Modal
      title={`${data ? "Update" : "Add"} Developer`}
      centered
      width={900}
      visible={open}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={handleSave}
        form={form}
        initialValues={
          data
            ? {
                ...data,
                city: data.city
                  ? {
                      value: data.city,
                      label: data.city_info.city,
                      key: data.city_info.id,
                      ...data.city_info,
                    }
                  : null,
              }
            : { status: 1 }
        }
      >
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Name"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input name",
                },
              ]}
            >
              <Input placeholder="developer name" />
            </Form.Item>
          </Col>
          {/* <Col flex="1 1 200px">
            <Form.Item
              label="Details"
              name="details"
              rules={[
                {
                  required: true,
                  message: "Please input details",
                },
              ]}
            >
              <Input placeholder="developer details" />
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="City"
              rules={[
                {
                  required: true,
                  message: "Please select city",
                },
              ]}
              name="city"
            >
              <SearchSelect
                style={{ width: "310px" }}
                placeholder={"Search by city"}
                fetchOptions={async (text) => {
                  return getOptions(text, { type: "city", labelKey: "city" });
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item label="Status" name="status">
              <Radio.Group>
                <Radio value={1}> Active </Radio>
                <Radio value={0}> In Active </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button loading={loading} type="primary" htmlType="submit">
              {"Save"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default DeveloperModal;
