import { InboxOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Upload } from "antd";
import * as XLSX from "xlsx";
import { API_ROOT_URL } from "../config";
import { DownloadOutlined } from "@ant-design/icons";
import { apiRequest } from "../api";
import apiPaths from "../api/paths";
import download from "downloadjs";

const { Dragger } = Upload;

function DragUpload({
  onFileData = () => {},
  note = "upload a excel file to import data",
  onFile,
  template,
  marginTop = 0,
}) {
  const extractData = (file) => {
    let reader = new FileReader();

    reader.onload = (e) => {
      let bstr = e.target.result;
      if (onFile) {
        onFile(bstr, file);
        return;
      }
      const wb = XLSX.read(bstr, { type: "binary", cellDates: true });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      onFileData(data);
    };

    reader.readAsBinaryString(file);
  };

  const downLoadTemplateFile = async () => {
    let res = await apiRequest({
      path: apiPaths.templates + `/?template=${template}`,
      file: true,
    });
    download(res, `${template}-template.csv`, "csv");
  };

  const menuItems = [
    {
      label: "Downlod Template",
      key: "1",
      onClick: downLoadTemplateFile,
      icon: <DownloadOutlined />,
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        marginTop: 15,
        marginBottom: 15,
        marginTop: marginTop,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          padding: 10,
        }}
      >
        <div>
          <Button type="primary" onClick={downLoadTemplateFile}>
            Download Sample CSV file
          </Button>
        </div>
      </div>
      <Dragger
        name="file"
        multiple={true}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        action={API_ROOT_URL + "/files"}
        onChange={(info) => {
          const { status } = info.file;

          if (status === "done") {
            extractData(info.file.originFileObj);
          } else if (status === "error") {
            message.error(`${info.file.name} file upload failed.`);
          }
        }}
        showUploadList={false}
      >
        <div>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">{note}</p>
        </div>
      </Dragger>
    </div>
  );
}

export default DragUpload;
