import { Button, Input, Space, Tag, Tooltip } from "antd";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

function MultipleValueInput({ placeholder, onChange = () => {}, ...props }) {
  const [inputValue, setInputValue] = useState("");
  const [refLinks, setRefLinks] = useState(props.value || []);

  const handleAddLink = () => {
    let updatedLinks = [...refLinks, inputValue];
    setRefLinks(updatedLinks);
    onChange(updatedLinks);
    setInputValue("");
  };

  const handleInputValue = (e) => {
    setInputValue(e.target.value);
  };

  const getDomainName = (url) => {
    let givenURL;
    try {
      givenURL = new URL(url);
    } catch (error) {
      return false;
    }
    return givenURL.hostname;
  };

  const handleClose = (removedTag) => {
    const newTags = refLinks.filter((tag) => tag !== removedTag);
    setRefLinks(newTags);
    onChange(newTags);
  };

  return (
    <Space direction="vertical">
      <div style={{ display: "flex" }}>
        <Input
          value={inputValue}
          placeholder={placeholder}
          onChange={handleInputValue}
          allowClear={true}
          style={{ width: "100%" }}
        />
        <Tooltip>
          <Button
            disabled={inputValue && getDomainName(inputValue) ? false : true}
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddLink}
            style={{ marginLeft: "10px" }}
          />
        </Tooltip>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {refLinks?.map((item, idx) => {
          return (
            <Tag
              style={{
                marginBottom: 10,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              closable
              onClose={() => handleClose(item)}
              key={idx}
            >
              {getDomainName(item)}
            </Tag>
          );
        })}
      </div>
    </Space>
  );
}

export default MultipleValueInput;
