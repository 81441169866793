import { Card, message, Spin } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDashboardStats } from "../../actions/user";

function Dashboard() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.token.userData);
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.user.dashStats);

  let isAdmin = userData.roleId == 1;
  let isUser = userData.roleId == 3;
  const fetchStats = async () => {
    const res = await dispatch(getDashboardStats());
    if (res.error) {
      message.error(res.error_description);
      return;
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  const COLORS = [
    "#008DDA",
    "#41C9E2",
    "#6420AA",
    "#FF3EA5",
    "#FF7ED4",
    "#FFB5DA",
    "#007F73",
    "#4CCD99",
    "#FFC700",
    "#FFF455",
    "#222831",
    "#76ABAE",
    "#8E7AB5",
    "#B784B7",
    "#E493B3",
    "#EEA5A6",
  ];

  return (
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      {stats ? (
        <div style={{ padding: "15px 20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <Card
              hoverable
              className="valueCards"
              style={{
                backgroundColor: "#181541",
                color: "#fff",
                borderRadius: 20,
                background: "linear-gradient(#9D50BB,#6E48AA)",
              }}
              onClick={() => {
                navigate("/leads");
              }}
            >
              <div>Total Leads</div>
              <div className="valueFont">{stats.total_leads}</div>
            </Card>
            {isUser ? null : (
              <Card
                hoverable
                className="valueCards"
                style={{
                  backgroundColor: "#181541",
                  color: "#fff",
                  borderRadius: 20,
                  // marginLeft: 15,
                  background: "linear-gradient(#009E9D,#93F8AF)",
                }}
                onClick={() => {
                  navigate("/leads", {
                    state: {
                      filteredValues: {
                        status: [5],
                      },
                    },
                  });
                }}
              >
                <div>Total Revenue</div>
                <div className="valueFont">{"₹ " + stats.total_revenue}</div>
              </Card>
            )}
            <Card
              hoverable
              className="valueCards"
              style={{
                backgroundColor: "#181541",
                color: "#fff",
                borderRadius: 20,
                // marginLeft: 15,
                background: "linear-gradient(#F0ff05,#F59C28)",
              }}
              onClick={() => {
                if (stats.pending_followups?.count)
                  navigate("/leads", {
                    state: {
                      filteredValues: {
                        follow_up: [
                          "2000-01-01 00:00:00",
                          moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                        ],
                        ...stats.pending_followups.filter,
                      },
                    },
                  });
              }}
            >
              <div style={{ color: "green" }}>Pending Follow Ups</div>
              <div className="valueFont">
                {stats.pending_followups?.count}
                <span style={{ fontWeight: "normal", fontSize: 12 }}>
                  {" (pending)"}
                </span>
              </div>
            </Card>
            <Card
              hoverable
              className="valueCards"
              style={{
                backgroundColor: "#181541",
                color: "#fff",
                borderRadius: 20,
                // marginLeft: 15,
                background: "linear-gradient(#F05C05,#F59C28)",
              }}
              onClick={() => {
                if (stats.followups_today)
                  navigate("/leads", {
                    state: {
                      filteredValues: {
                        follow_up: [
                          moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                          moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                        ],
                      },
                    },
                  });
              }}
            >
              <div>Follow Ups Today</div>
              <div className="valueFont">
                {stats.followups_today?.count || stats.followups_today}
                <span style={{ fontWeight: "normal", fontSize: 12 }}>
                  {" (pending)"}
                </span>
              </div>
            </Card>
            {isAdmin ? null : (
              <Card
                hoverable
                className="valueCards"
                style={{
                  backgroundColor: "#181541",
                  color: "#fff",
                  borderRadius: 20,
                  // marginLeft: 15,
                  background: "linear-gradient(#011B7C,#5BB2DB)",
                }}
                onClick={() => {
                  if (stats.today_assigned?.count)
                    navigate("/leads", {
                      state: {
                        filteredValues: {
                          assignedDate: [
                            moment()
                              .startOf("day")
                              .format("YYYY-MM-DD HH:mm:ss"),
                            moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                          ],
                        },
                      },
                    });
                }}
              >
                <div>Assigned Today</div>
                <div className="valueFont">{stats.today_assigned?.count}</div>
              </Card>
            )}
          </div>
          <div
            style={{
              marginTop: 40,
              display: "flex",
              //   alignItems: "flex-start",
            }}
          >
            <div
              style={{
                backgroundColor: "#B2BACC",
                padding: "15px 20px",
                borderRadius: 20,
                background: "linear-gradient(#AAEAE860,#E8EDED60,#AAEAE860)",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 20,
                  marginBottom: 20,
                  color: "#011E3D",
                }}
              >
                {"Leads By Status"}
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {Object.values(stats.status_stats || {}).map((item, idx) => (
                  <Card
                    key={idx}
                    size="small"
                    className="status-card"
                    style={{
                      // backgroundColor: COLORS[idx],
                      borderRadius: 20,
                      marginBottom: 15,
                      marginRight: 15,
                      color: "#011E3D",
                      borderLeftWidth: 5,
                      borderColor: COLORS[idx],
                    }}
                    hoverable
                    onClick={() => {
                      navigate("/leads", {
                        state: {
                          filteredValues: {
                            status: [item.statusId],
                            leads: item.leads,
                          },
                        },
                      });
                    }}
                  >
                    <div style={{ fontSize: 13 }}>
                      {item.status || "Un Known"}
                    </div>
                    <div
                      style={{ width: "15%", fontSize: 28, textAlign: "end" }}
                    >
                      {item.count}
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spin />
      )}
    </div>
  );
}

export default Dashboard;
