const apiPaths = {
  token: "/token/",
  self: "/users/self/",
  users: "/users",
  campains: "/campains",
  stations: "/dock-stations",
  vehicles: "/vehicles",
  rides: "/rides",
  leads: "/leads",
  bookings: "/bookings",
  developers: "/developers",
  categories: "/categories",
  cities: "/cities",
  leadSources: "/lead-sources",
  statuses: "/statuses",
  projects: "/projects",
  roles: "/roles",
  priorities: "/priorities",
  stats: "/dashboard",
  constants: "/constants",
  templates: "/templates",
  agreements: "/agreements",
  mail: "/mail",
  notifications: "/notifications",
  activities: "/activities",
};

export default apiPaths;
