import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React from "react";

function TableActionDropDown({ showColumns, setShowColumns = () => {} }) {
  return (
    <div style={{ display: "flex" }}>
      <Dropdown
        dropdownRender={() => (
          <div
            style={{
              height: 280,
              padding: 20,
              backgroundColor: "#fff",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
            }}
          >
            <div>Show / Hide Columns</div>
            <div
              style={{
                height: "90%",
                overflow: "auto",
                marginTop: 10,
                padding: "0px 10px",
              }}
            >
              {Object.values(showColumns).map((item, key) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "4px 8px",
                  }}
                >
                  <div style={{ marginRight: "8px" }}>{item.label}</div>
                  <input
                    type="checkbox"
                    checked={item.show}
                    onChange={(e) => {
                      setShowColumns({
                        ...showColumns,
                        [item.key]: {
                          ...item,
                          show: e.target.checked,
                        },
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        trigger={["click"]}
      >
        <Button type="link" onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined style={{ fontSize: 25 }} />
        </Button>
      </Dropdown>
    </div>
  );
}

export default TableActionDropDown;
