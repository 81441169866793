import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import swal from "sweetalert";
import apiPaths from "../../api/paths";
import { apiRequest } from "../../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import images from "../../assets/images";

function PasswordPage() {
  const [misMatch, setMismatch] = useState(false);
  const [form] = Form.useForm();
  const password = Form.useWatch("password", form);
  const navigate = useNavigate();
  const query = useParams();

  const handleFormSubmit = async (values) => {
    if (values.password !== values.repeat_password) {
      setMismatch(true);
      form.setFieldsValue({ ...values, repeat_password: "" });
      return;
    }
    const res = await apiRequest({
      path: apiPaths.setPassword + "/" + query.userid,
      method: "POST",
      secured: false,
      data: {
        ...values,
        verification_id: query.verifid,
      },
    });
    if (res.error) {
      swal(res.error, res.error_description, "error");
      return;
    }
    swal(
      "Password set",
      "Your password has been set and account activated.",
      "success"
    ).then(() => {
      navigate("/login");
    });
  };

  const getStatus = (password) => {
    if (!form.getFieldError("password").length && !password) {
      return "";
    }
    if (!password) {
      return "error";
    }
    if (password?.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)) {
      return "success";
    }
    return "warning";
  };

  return (
    <div
      style={{
        ...styles.flex,
        height: "100vh",
        width: "100vw",
        backgroundColor: "#000",
        position: "relative",
      }}
    >
      <div style={styles.content}>
        <div style={styles.contentLeft}>
          <img
            src={images.signup_bg}
            style={{
              position: "absolute",
              height: "100%",
            }}
          />

          <div style={{ zIndex: 1, padding: "5%", marginTop: 150 }}>
            <img src={images.logo} style={styles.logo} />
            <h1 style={{ color: "#fff", fontSize: 60 }}>Welcome !</h1>
          </div>
        </div>
        <div style={styles.contentRight}>
          <h1 style={{ marginBottom: 10, fontSize: 35 }}>Set Password</h1>
          <div style={{ marginBottom: 20 }}>
            Set Password to activate your account.
          </div>
          <div style={{ color: "#c1c1c1" }}>
            {
              "*Password must have at least one digit, one lower and one upper case character and one special character and be between 8 and 20 characters"
            }
          </div>
          <div style={styles.form}>
            <Form layout="vertical" form={form} onFinish={handleFormSubmit}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
                validateStatus={getStatus(password)}
              >
                <Input.Password
                  placeholder="Enter password"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
              <Form.Item
                name="repeat_password"
                label="Confirm Password"
                hasFeedback
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password",
                  },
                  {
                    validator: (_, value) => {
                      if (!value || password === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter same password"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
              <div
                style={{
                  ...styles.flex,
                  marginTop: 25,
                  flexDirection: "column",
                }}
              >
                {misMatch ? (
                  <div style={{ color: "red" }}>
                    Please enter the same password
                  </div>
                ) : null}
                <Form.Item>
                  <Button
                    style={{
                      width: 200,
                      marginTop: 25,
                      backgroundColor: "#000",
                      borderColor: "#000",
                      color: "#fff",
                    }}
                    htmlType="submit"
                    type="primary"
                  >
                    Set Password
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    height: "80%",
    zIndex: 1,
    width: "80%",
    borderRadius: 10,
    display: "flex",
    overflow: "hidden",
  },
  contentLeft: {
    display: "flex",
    flex: 1.2,
    overflow: "hidden",
    position: "relative",
  },
  contentRight: {
    display: "flex",
    flex: 0.8,
    flexDirection: "column",
    padding: "3%",
    backgroundColor: "#fff",
    justifyContent: "center",
  },
  logo: {
    position: "absolute",
    height: 60,
    width: 80,
    right: 20,
    top: 20,
  },
  form: {
    padding: "25px 15%",
    backgroundColor: "#fff",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

export default PasswordPage;
