import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login";
import PasswordPage from "./Password";

function PublicPages() {
  const tabs = [
    {
      path: "/login",
      component: <Login />,
    },
    {
      path: "/password/:userid/:verifid",
      component: <PasswordPage />,
    },
  ];
  const renderRoute = ({ path, component }) => (
    <Route key={path} path={path}>
      <Route index element={component} />
    </Route>
  );

  return (
    <div className="cover flex">
      <Routes>
        <Route
          path="/"
          caseSensitive
          element={<Navigate to="/login" replace />}
        />
        <Route path="/login" caseSensitive />
        {tabs.map((tab) => renderRoute(tab))}
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </div>
  );
}

export default PublicPages;
