import { Badge, DatePicker, message, Space, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { apiRequest } from "../../api";
import apiPaths from "../../api/paths";
import DynamicTable from "../../components/DynamicTable";

function Activities() {
  const [stats, setStats] = useState([]);
  const [activitiesDate, setActivitiesDate] = useState(moment());

  const getActivitiesStats = async (date) => {
    const res = await apiRequest({
      path:
        apiPaths.activities +
        `?start_date=${moment(date).startOf("day")}&end_date=${moment(
          date
        ).endOf("day")}`,
    });
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    setStats(res);
  };

  useEffect(() => {
    getActivitiesStats(new Date());
  }, []);

  const COLUMNS = [
    {
      title: "User Name",
      dataIndex: "name",
      filterType: "search",
    },
    {
      title: "User Email",
      dataIndex: "email",
      filterType: "search",
    },
    {
      title: "Total Activities",
      dataIndex: "total",
    },
    {
      title: "Activities",
      dataIndex: "activities",
      render: (_, record) => {
        return Object.keys(record.activities).map((activity, idx) => (
          <Badge
            style={{ marginRight: 10 }}
            size="small"
            count={record.activities[activity]}
            key={idx}
          >
            <Tag>{activity}</Tag>
          </Badge>
        ));
      },
    },
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          display: "flex",
          marginBottom: 20,
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: 20 }}>User Activities</div>
        <Space>
          <DatePicker
            value={activitiesDate}
            style={{ width: 200 }}
            placeholder="Activities Date"
            onSelect={(value) => {
              setActivitiesDate(value);
              getActivitiesStats(value.toISOString());
            }}
          />
        </Space>
      </div>
      <div style={{ height: "90%" }}>
        <DynamicTable
          dataSource={stats}
          columns={COLUMNS}
          pagination={false}
          requireEdit={false}
          showActionColumn={false}
          scroll={{ y: "100%" }}
        />
      </div>
    </div>
  );
}

export default Activities;
