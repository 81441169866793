import { Button, Col, Form, Input, Modal, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../api";
import apiPaths from "../../../api/paths";
import SearchSelect from "../../../components/SearchSelect";

function AddUserModal({
  open = false,
  onCancel = () => {},
  onAdd = () => {},
  onUpdate = () => {},
  data,
}) {
  const [managers, setManagers] = useState([]);
  const [form] = Form.useForm();
  const roleValue = Form.useWatch("role", form);

  const getOptions = async (searchText, optionObj = {}) => {
    const { type, valueKey = "id", labelKey = "title" } = optionObj;
    let url = "";
    switch (type) {
      case "role": {
        url = apiPaths.roles + `?role_contains=${searchText}`;
        break;
      }
      default: {
        return [];
      }
    }
    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    return options.map((item, index) => ({
      ...item,
      key: index,
      value: item[valueKey],
      _id: item[valueKey],
      label: `${item[labelKey]}`,
    }));
  };

  const getManagers = async () => {
    let url = apiPaths.users + `?roleId=2`;
    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    options = options.map((item, index) => ({
      ...item,
      key: index,
      value: item.userId,
      _id: item.userId,
      label: `${item.name}`,
    }));
    setManagers(options);
  };

  useEffect(() => {
    if (roleValue?.value == 3) {
      getManagers();
    }
  }, [roleValue]);

  const handleOnSave = async (values) => {
    values.roleId = values.role.value;
    delete values.role;

    if (values.manager) {
      values.manager = values.manager.value;
    }
    if (data) {
      onUpdate(values, data.userId);
    } else {
      onAdd(values);
    }
  };

  return (
    <Modal
      title={"Add User"}
      centered
      width={900}
      visible={open}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        initialValues={
          data
            ? {
                ...data,
                manager: data.manager_info
                  ? {
                      ...data.manager_info,
                      key: 0,
                      value: data.manager_info.userId,
                      _id: data.manager_info.userId,
                      label: data.manager_info.name,
                    }
                  : null,
                role: {
                  ...data.role,
                  value: data.role.roleId,
                  _id: data.role.roleId,
                  label: data.role.role,
                },
                password: "",
              }
            : {}
        }
        form={form}
        onFinish={handleOnSave}
      >
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Full Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input Full Name",
                },
              ]}
            >
              <Input placeholder="Full Name" />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input Email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Mobile"
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please input Mobile",
                },
              ]}
            >
              <Input max={10} placeholder="Mobile" />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              label="Role"
              tooltip="Choose Role (Need to type atleast 3 character) "
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please select Role",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "310px" }}
                placeholder={"Search by role"}
                fetchOptions={async (text) => {
                  return getOptions(text, {
                    type: "role",
                    valueKey: "roleId",
                    labelKey: "role",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          {roleValue?.value == 3 ? (
            <Col flex="1 1 200px">
              <Form.Item
                label="Manager"
                tooltip="Choose Manager"
                name="manager"
                rules={[
                  {
                    required: true,
                    message: "Please select Manager",
                  },
                ]}
              >
                <SearchSelect
                  style={{ width: "310px" }}
                  placeholder={"Search by manager"}
                  options={managers}
                  showSearch={false}
                />
              </Form.Item>
            </Col>
          ) : null}
          <Col flex="1 1 200px">
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: data ? false : true,
                  message: "Please input Password",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              {"Add"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddUserModal;
