import { FilterOutlined } from "@ant-design/icons";
import { Badge, Button, DatePicker, Form, Modal, Select, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import useMobileOrDesktop from "../../../hooks/useMobileOrDesktop";

function Filters({
  options = {},
  initialValue = null,
  onFilter = () => {},
  onClearFilter = () => {},
}) {
  const [followUpDate, setFollowUpDate] = useState(initialValue?.follow_up);
  const [showFilter, setShowFilter] = useState(false);
  const [filterValues, setFilterValues] = useState(initialValue);
  const isMobile = useMobileOrDesktop();

  useEffect(() => {
    setFollowUpDate(initialValue?.follow_up);
    setFilterValues(initialValue);
  }, [initialValue]);

  const handleClear = () => {
    setShowFilter(false);
    onClearFilter();
  };

  const handleFinish = (values) => {
    if (values.follow_up?.length) {
      let followUpStartDate = moment(values.follow_up[0])
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      let followUpEndDate = moment(values.follow_up[1])
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      values.follow_up = [followUpStartDate, followUpEndDate];
    }
    setShowFilter(false);
    onFilter(values);
  };

  const showBadge = (initialValue) => {
    if (
      initialValue?.follow_up ||
      initialValue?.status ||
      initialValue?.project
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {isMobile ? (
        <div>
          <Button onClick={() => setShowFilter(true)}>
            <Badge dot={showBadge(initialValue)}>
              <FilterOutlined />
            </Badge>
          </Button>
          {showFilter ? (
            <Modal
              title={"Leads Filter"}
              centered
              width={900}
              maskClosable={false}
              visible={true}
              onCancel={() => setShowFilter(false)}
              footer={null}
            >
              <Form
                onFinish={handleFinish}
                initialValues={
                  filterValues
                    ? {
                        follow_up: filterValues.follow_up
                          ? [
                              moment(filterValues.follow_up[0]),
                              moment(filterValues.follow_up[1]),
                            ]
                          : null,
                        status: filterValues.status || undefined,
                        project: filterValues.project || undefined,
                      }
                    : {}
                }
              >
                <Form.Item label="Follow Up date" name="follow_up">
                  <DatePicker.RangePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
                <Form.Item label={"Status"} name="status">
                  <Select mode="multiple" placeholder="Select status">
                    {options.status?.map((option, idx) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={"Project"} name="project">
                  <Select mode="multiple" placeholder="Select project">
                    {options.project?.map((option, idx) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Space>
                      <Button onClick={handleClear}>Clear</Button>
                      <Button type="primary" htmlType="submit">
                        Filter
                      </Button>
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
          ) : null}
        </div>
      ) : (
        <Space wrap={true}>
          {"Follow Up: "}
          <DatePicker.RangePicker
            clearIcon={false}
            value={
              followUpDate
                ? [
                    followUpDate[0] ? moment(followUpDate[0]) : null,
                    followUpDate[1] ? moment(followUpDate[1]) : null,
                  ]
                : followUpDate
            }
            style={{ width: 200 }}
            onChange={(e, dates) => {
              if (!e) {
                setFollowUpDate(e);
                return;
              }
              let dateFrom = dates[0] + "T00:00:00";
              let dateTo = dates[1] + "T23:59:59";
              setFollowUpDate([dateFrom, dateTo]);
            }}
            format="YYYY-MM-DD"
          />
          <Button onClick={handleClear}>Clear</Button>
          <Button
            type="primary"
            disabled={followUpDate ? false : true}
            onClick={() => onFilter({ follow_up: followUpDate })}
          >
            Filter
          </Button>
        </Space>
      )}
    </>
  );
}

export default Filters;
