import { Button, message, Modal, Table } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateLeadData } from "../../../actions/lead";
import { apiRequest } from "../../../api";
import apiPaths from "../../../api/paths";
import StatusChange from "./StatusChange";

function FollowUps({ open = false, leadId, onClose = () => {} }) {
  const [showForm, setShowForm] = useState(false);
  const [followUps, setFollowUps] = useState([]);
  const loadingRef = useRef(true);
  const dispatch = useDispatch();

  const handleOnSave = async (values) => {
    const res = await apiRequest({
      path: apiPaths.leads + `/${leadId}/follow-ups`,
      method: "POST",
      data: values,
    });
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    let updated = [res, ...followUps];
    dispatch(updateLeadData({ id: leadId, follow_up: updated }));
    setFollowUps(updated);
    setShowForm(false);
  };

  const getFollowUps = async (leadId) => {
    const res = await apiRequest({
      path: apiPaths.leads + `/${leadId}/follow-ups`,
    });
    loadingRef.current = false;
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    setFollowUps(res.results);
  };

  useEffect(() => {
    getFollowUps(leadId);
  }, [leadId]);

  return (
    <>
      {showForm ? (
        <StatusChange
          open={true}
          onSave={handleOnSave}
          onCancel={() => {
            setShowForm(false);
          }}
        />
      ) : (
        <Modal
          title={"Lead Follow Ups"}
          width={900}
          visible={open}
          onCancel={onClose}
          footer={null}
        >
          <div style={{ width: "100%" }}>
            <Table
              loading={loadingRef.current}
              cellFontSize={11}
              columns={[
                // {
                //   title: "ID",
                //   dataIndex: "id",
                //   render: (value) => value,
                // },

                {
                  title: "Follow Up Date",
                  dataIndex: "followUpDate",
                  width: 100,
                  render: (value) => {
                    return value && moment(value).format("DD-MM-YYYY hh:mm a");
                  },
                },

                {
                  title: "Follow Up Summery",
                  dataIndex: "comment",
                  width: 130,
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  width: 70,
                  render: (_, record) => {
                    return record.status_info?.status;
                  },
                },
                {
                  title: "Created Date",
                  dataIndex: "createdDate",
                  width: 100,
                  render: (value) => {
                    return moment(value).format("DD-MM-YYYY hh:mm a");
                  },
                },
                {
                  title: "Created By",
                  width: 100,
                  dataIndex: "createdBy_info",
                  render: (_, record) => {
                    return record.createdBy_info?.name;
                  },
                },
              ]}
              dataSource={followUps}
              rowKey={"id"}
              size="small"
              scroll={{ x: 1000 }}
              pagination={false}
            />
          </div>

          <div
            style={{
              justifyContent: "flex-end",
              display: "flex",
              marginTop: 15,
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setShowForm(true);
              }}
            >
              {"+ Add Follow Up"}
            </Button>
            <Button type="ghost" style={{ marginLeft: 10 }} onClick={onClose}>
              {"Close"}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default FollowUps;
