//token user login actions
export const SET_USER_TOKEN_DATA = "SET_USER_TOKEN_DATA";
export const REMOVE_USER_TOKEN_DATA = "REMOVE_USER_TOKEN_DATA";
export const SET_SELF_USER_DATA = "SET_SELF_USER_DATA";
export const REMOVE_USER_DATA = "REMOVE_USER_DATA";
export const SET_USER_NOTIFICATIONS = "SET_USER_NOTIFICATIONS";
export const ADD_USER_NOTIFICATION = "ADD_USER_NOTIFICATION";
export const READ_USER_NOTIFICATION = "READ_USER_NOTIFICATION";

// leads actions
export const SET_LEADS = "SET_LEADS";
export const ADD_LEAD = "ADD_LEAD";
export const UPDATE_LEAD_DATA = "UPDATE_LEAD_DATA";
export const UPDATE_LEADS_DATA = "UPDATE_LEADS_DATA";
export const DELETE_LEADS = "DELETE_LEADS";
export const SET_LEADS_FILTERS = "SET_LEADS_FILTERS";
export const SET_LEADS_PAGINATION = "SET_LEADS_PAGINATION";

// bookings actions
export const SET_BOOKINGS = "SET_BOOKINGS";
export const UPDATE_BOOKING = "UPDATE_BOOKING";

// user actions
export const SET_DASH_STATS = "SET_DASH_STATS";
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
