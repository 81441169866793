import { Button, Col, Form, message, Modal, Radio, Row, Space } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { assignUserToLeads } from "../../../actions/lead";
import { apiRequest } from "../../../api";
import apiPaths from "../../../api/paths";
import SearchSelect from "../../../components/SearchSelect";

function AssignUser({
  open = false,
  onSave = () => {},
  onCancel = () => {},
  lead = {},
  multiLead,
}) {
  const [isManager, setIsManager] = useState(false);
  const dispatch = useDispatch();
  const getOptions = async (searchText) => {
    let valueKey = "userId";
    let labelKey = "name";
    var url =
      apiPaths.users +
      `?roleId=${isManager ? 2 : 3}&name_contains=${searchText}`;

    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    return options.map((item, index) => ({
      ...item,
      key: index,
      value: item[valueKey],
      _id: item[valueKey],
      label: `${item[labelKey]}`,
    }));
  };

  // useEffect(() => {
  //   getOptions("");
  // }, []);

  const handleOnSave = async (values) => {
    var res = await dispatch(
      assignUserToLeads({
        leads: multiLead,
        is_manager: isManager,
        userId: values.user.value,
        user: values.user,
      })
    );
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    message.success("User Assigned");
    onSave();
  };

  return (
    <Modal
      title={"Assign User"}
      // width={900}
      visible={open}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        initialValues={{ isManager: false }}
        layout="vertical"
        onFinish={handleOnSave}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Assign as" name="isManager">
              <Radio.Group
                style={{
                  flexDirection: "row",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                onChange={(e) => setIsManager(e.target.value)}
                value={isManager}
              >
                <Radio value={true}>Manager</Radio>
                <Radio value={false}>Relation Manager</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            {isManager ? (
              <Form.Item
                label="User (Manager)"
                key="sss"
                tooltip="Choose User (Need to type atleast 3 character) "
                name="user"
                rules={[
                  {
                    required: true,
                    message: `Please select user`,
                  },
                ]}
              >
                <SearchSelect
                  key={"sss"}
                  style={{ width: "310px" }}
                  placeholder={"Search by name"}
                  fetchOptions={async (text) => {
                    return getOptions(text);
                  }}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="User (RM)"
                key="ss"
                tooltip="Choose User (Need to type atleast 3 character) "
                name="user"
                rules={[
                  {
                    required: true,
                    message: `Please select user`,
                  },
                ]}
              >
                <SearchSelect
                  style={{ width: "310px" }}
                  key={"sss"}
                  placeholder={"Search by name"}
                  fetchOptions={async (text) => {
                    return getOptions(text);
                  }}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              {"Assign"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AssignUser;
