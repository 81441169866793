import { SET_CAMPAIGNS, SET_DASH_STATS } from "../actions/types";

const initialState = {
  dashStats: null,
  campaigns: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASH_STATS: {
      return {
        ...state,
        dashStats: action.data,
      };
    }
    case SET_CAMPAIGNS: {
      return {
        ...state,
        campaigns: action.data,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
