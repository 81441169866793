import { message } from "antd";
import _ from "lodash";
import { useEffect } from "react";
import DynamicTable from "../../components/DynamicTable";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "../../actions/user";

function Campains() {
  const campains = useSelector((state) => state.user.campaigns) || {};
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCampains();
  }, []);

  const fetchCampains = async () => {
    const res = await dispatch(getCampaigns());
    if (res.error) {
      message.error(res.error_description);
    }
  };

  const COLUMNS = [
    {
      title: "Developer",
      dataIndex: "developer",
      width: 100,
      filterType: "search",
    },
    {
      title: "Project",
      dataIndex: "project",
      width: "100px",
      filterType: "search",
    },
    {
      title: "Campaign ID",
      dataIndex: "url",
      filterType: "search",
      width: "150px",
      render: (value) => {
        return (
          <a
            onClick={() => {
              navigator.clipboard.writeText(value.split("/").slice(-1));
              message.success("ID copied");
            }}
          >
            {value.split("/").slice(-1)}
          </a>
        );
      },
    },
    {
      title: "Microsite Url",
      dataIndex: "url",
      filterType: "search",
      width: "250px",
      render: (value) => {
        return (
          <a
            onClick={() => {
              navigator.clipboard.writeText(value);
              message.success("Linked copied");
            }}
          >
            {value}
          </a>
        );
      },
    },
  ];

  return (
    <div style={{ height: "100%" }}>
      <DynamicTable
        dataSource={Object.values(campains)}
        columns={COLUMNS}
        rowKey="id"
        scroll={{
          // x: 2250,
          y: "90%",
        }}
        showActionColumn={false}
      />
    </div>
  );
}

export default Campains;
