import {
  REMOVE_USER_TOKEN_DATA,
  SET_SELF_USER_DATA,
  SET_USER_TOKEN_DATA,
  REMOVE_USER_DATA,
  SET_USER_NOTIFICATIONS,
  ADD_USER_NOTIFICATION,
  READ_USER_NOTIFICATION,
} from "../actions/types";

const initialState = {
  tokenData: null,
  userData: {},
  notifications: [],
  notificationsNotSeen: 0,
};

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_TOKEN_DATA: {
      return {
        ...state,
        tokenData: action.data,
      };
    }
    case READ_USER_NOTIFICATION: {
      return {
        ...state,
        notificationsNotSeen: action.data,
      };
    }
    case SET_USER_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.data,
      };
    }
    case ADD_USER_NOTIFICATION: {
      return {
        ...state,
        notificationsNotSeen: state.notificationsNotSeen + 1,
        notifications: [action.data, ...state.notifications],
      };
    }
    case SET_SELF_USER_DATA: {
      return {
        ...state,
        userData: action.data,
      };
    }
    case REMOVE_USER_TOKEN_DATA: {
      return {
        ...state,
        tokenData: null,
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state,
        userData: {},
      };
    }
    default:
      return state;
  }
};

export default tokenReducer;
