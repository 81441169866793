import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Upload,
} from "antd";
import { apiRequest } from "../../../api";
import apiPaths from "../../../api/paths";
import { API_ROOT_URL } from "../../../config";
import download from "downloadjs";
import SearchSelect from "../../../components/SearchSelect";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateBooking } from "../../../actions/bookings";

function BookingModal({
  open = false,
  onSave = () => {},
  onCancel = () => {},
  data = {},
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [projects, setProjects] = useState([]);
  const developerValue = Form.useWatch("developer", form);
  const projectValue = Form.useWatch("project", form);

  const getOptions = async (searchText, optionObj = {}) => {
    const { type, valueKey = "id", labelKey = "title" } = optionObj;
    let url = "";
    switch (type) {
      case "developer": {
        url = apiPaths.developers + `?title_contains=${searchText}`;
        break;
      }
      case "project": {
        url = apiPaths.projects + `?title_contains=${searchText}`;
        break;
      }
      case "user": {
        url = apiPaths.users + `?name_contains=${searchText}`;
        break;
      }
      default: {
        return [];
      }
    }
    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    return options.map((item, index) => ({
      ...item,
      key: index,
      value: item[valueKey],
      _id: item[valueKey],
      label: `${item[labelKey]}`,
    }));
  };

  const getProjects = async (developer) => {
    let url = apiPaths.projects + `?developer=${developer}`;
    const res = await apiRequest({
      path: url,
    });
    let options = [];
    if (res.results) {
      options = res.results;
    }
    options = options.map((item, index) => ({
      ...item,
      key: index,
      value: item.id,
      _id: item.id,
      label: `${item.title}`,
    }));
    setProjects(options);
  };

  useEffect(() => {
    if (developerValue) {
      getProjects(developerValue.value);
      if (projectValue && projectValue.developer != developerValue.value)
        form.setFieldValue("project", undefined);
    }
  }, [developerValue]);

  const handleOnSave = async (values) => {
    let agreement = values.agreement;
    let createdBy = values.createdBy;
    delete values.agreement;
    delete values.createdBy;
    let formData = new FormData();
    Object.keys(values).map((key) => {
      if (values[key]) {
        formData.append(key, values[key]);
      }
    });
    if (agreement) {
      formData.append("agreement", agreement.originFileObj);
    }
    formData.append("project_id", values.project.value);
    formData.append("developer_id", values.developer.value);
    formData.append("createdBy", createdBy.value);

    const res = await dispatch(updateBooking(data.id, formData));
    if (!res.error) {
      message.success("Booking submitted successfully");
      onSave();
    } else {
      message.error(res.error_description);
    }
  };

  const downLoadAgreement = async (name) => {
    let res = await apiRequest({
      path: apiPaths.agreements + `/?name=${name}`,
      file: true,
    });
    download(res, name, "pdf");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.file;
  };

  return (
    <Modal
      title={"Update Booking Details"}
      centered
      width={900}
      visible={open}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...data,
          developer: data.developer
            ? {
                ...data.developer,
                value: data.developer.id,
                _id: data.developer.id,
                label: data.developer.title,
                key: data.developer.id,
              }
            : null,
          project: data.project
            ? {
                ...data.project,
                value: data.project.id,
                _id: data.project.id,
                label: data.project.title,
                key: data.project.id,
              }
            : null,
          createdBy: data.createdBy_info
            ? {
                ...data.createdBy_info,
                value: data.createdBy_info.userId,
                _id: data.createdBy_info.userId,
                label: data.createdBy_info.name,
                key: data.createdBy_info.userId,
              }
            : null,
        }}
        onFinish={handleOnSave}
      >
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Booking Name"
              name="booking_name"
              rules={[
                {
                  required: true,
                  message: "Please input Booking Name",
                },
              ]}
            >
              <Input placeholder="name" />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input Unit details",
                },
              ]}
              label="Unit Details"
              name="unit_details"
            >
              <Input placeholder="details" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Developer"
              tooltip="Choose Developer(Need to type atleast 3 character) "
              name="developer"
              rules={[
                {
                  required: true,
                  message: "Please select developer",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "310px" }}
                placeholder={"Search by title"}
                fetchOptions={async (text) => {
                  return getOptions(text, { type: "developer" });
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              label="Project"
              tooltip="Choose Project (Need to type atleast 3 character) "
              name="project"
              rules={[
                {
                  required: true,
                  message: "Please select project",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "310px" }}
                placeholder={"Search by title"}
                options={projects}
                showSearch={false}
                // fetchOptions={async (text) => {
                //   return getOptions(text, { type: "project" });
                // }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Brokerage %"
              name="brokerage_percent"
              rules={[
                {
                  required: true,
                  message: "Please input Brokerage %",
                },
              ]}
            >
              <InputNumber
                style={{ width: 200 }}
                placeholder="%"
                precision={2}
              />
            </Form.Item>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input Brokerage Amount",
                },
              ]}
              label="Brokerage Amount"
              name="brokerage_amount"
            >
              <InputNumber
                style={{ width: 200 }}
                placeholder="amount"
                precision={2}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Form.Item
              label="Aggreement"
              name="agreement"
              valuePropName="file"
              getValueFromEvent={normFile}
            >
              <Upload
                multiple={false}
                action={API_ROOT_URL + "/files"}
                listType="picture-card"
                accept=".pdf"
                maxCount={1}
              >
                <button style={{ border: 0, background: "none" }} type="button">
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </button>
              </Upload>
            </Form.Item>
            <div
              style={{
                fontSize: 12,
                color: "#b7b7b7",
                position: "absolute",
                bottom: 10,
              }}
            >
              * allowed pdf file only
            </div>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input Agreement value",
                },
              ]}
              label="Agreement Value"
              name="agreement_value"
            >
              <InputNumber
                style={{ width: 200 }}
                placeholder="value"
                precision={2}
              />
            </Form.Item>
            <Form.Item
              label="Booking Done By"
              tooltip="Choose User (Need to type atleast 3 character) "
              name="createdBy"
              rules={[
                {
                  required: true,
                  message: "Please select Booking Done By",
                },
              ]}
            >
              <SearchSelect
                style={{ width: "310px" }}
                placeholder={"Search by name"}
                fetchOptions={async (text) => {
                  return getOptions(text, {
                    type: "user",
                    valueKey: "userId",
                    labelKey: "name",
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col flex="1 1 200px">
            <Col flex="1 1 200px">
              <Form.Item label="Comment" name="comment">
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Col>
          <Col flex="1 1 200px">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input Agreement value",
                },
              ]}
              label="Cashback Value"
              name="cashback"
            >
              <InputNumber
                style={{ width: 200 }}
                placeholder="value"
                precision={2}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" htmlType="Update">
              {"Submit"}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default BookingModal;
