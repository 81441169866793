import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Select, Space, Spin, Typography } from "antd";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

function SearchSelect({
  fetchOptions,
  debounceTimeout = 1000,
  styles,
  showSearch = true,
  inputPlaceholder = "label",
  showAdd,
  onAdd = () => {},
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (!props.options) handleChange("");
  }, []);

  const handleSearch = useCallback((searchTerm) => {
    fetchOptions(searchTerm).then((newOptions) => {
      setFetching(false);
      setOptions(newOptions);
    });
  }, []);

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearch, 1000);
  }, [handleSearch]);

  const handleChange = (value) => {
    // setSearchValue(value);
    setFetching(true);
    if (showSearch) debouncedSearch(value);
  };

  return (
    <Select
      labelInValue
      style={{ ...styles }}
      filterOption={false}
      showSearch
      showArrow={showSearch}
      // onFocus={() => {
      //   debounceFetcher("");
      // }}
      onSearch={handleChange}
      loading={fetching}
      notFoundContent={
        null
        //(options && options.length === 0) || !options ? "No data found" : null
      }
      options={options}
      dropdownRender={(menu) => (
        <>
          {menu}
          {showAdd ? (
            <>
              <Divider style={{ margin: "8px 0" }} />
              <Space align="center" style={{ padding: "0 8px 4px" }}>
                <Input
                  placeholder={`Please enter ${inputPlaceholder}`}
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
                <Typography.Link
                  onClick={async () => {
                    if (value) {
                      await onAdd(value);
                      setValue("");
                      handleChange("");
                    }
                  }}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <PlusOutlined /> {`Add new option`}
                </Typography.Link>
              </Space>
            </>
          ) : null}
        </>
      )}
      {...props}
    />
  );
}

export default SearchSelect;
