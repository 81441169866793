import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { apiRequest } from "../api";
import apiPaths from "../api/paths";
import { API_ROOT_URL } from "../config";

function UploadImage({
  onChange = () => {},
  placeholder = "Upload",
  ...props
}) {
  const handleImageUpload = async (file) => {
    if (file && file.status === "done") {
      let formData = new FormData();
      formData.append("file", file.originFileObj);
      let res = await apiRequest({
        path: apiPaths.uploadFiles,
        method: "POST",
        formData,
      });
      if (!res.url) {
        message.error("Error in uploading file, try after sometimes");
      }
      onChange(res.url);
    } else if (file.status === "removed") {
      onChange("");
    }
  };

  return (
    <Upload
      action={API_ROOT_URL + "/files"}
      name="file"
      accept="image"
      multiple={false}
      showUploadList={true}
      maxCount={1}
      defaultFileList={
        props?.value && [{ uid: "1", name: props.value, url: props.value }]
      }
      onChange={({ file, fileList }) => {
        if (file.status !== "uploading") {
          handleImageUpload(file);
        }
      }}
    >
      <Button icon={<UploadOutlined />}>{placeholder}</Button>
    </Upload>
  );
}

export default UploadImage;
