import { apiRequest } from "../api";
import { APP_MODE } from "../config";

export const getMoreData = ({ path, tokenData, data, onFinish = () => {} }) => {
  apiRequest({ path, tokenData }).then((res) => {
    if (res.error) {
      onFinish(data);
    } else {
      data = [...data, ...res.results];
      if (res.next) {
        getMoreData({ path: res.next, tokenData, data, onFinish });
      } else {
        onFinish(data);
      }
    }
  });
};

export const convertToFilterOptions = (data) => {
  let result = data?.reduce((acc, curr) => {
    acc.push({ ...curr, text: curr.label });
    return acc;
  }, []);
  return result;
};

export const dateToDurationString = (date = null, isNeedsSuffix = true) => {
  let difference = new Date() - new Date(date);
  let suffix = " ago";
  if (difference < 0) {
    difference = new Date(date) - new Date();
    suffix = " left";
  }
  let weeks = Math.floor(difference / 1000 / 60 / 60 / 24 / 7);
  let days = Math.floor(difference / 1000 / 60 / 60 / 24 - weeks * 7);
  let hours = Math.floor(
    difference / 1000 / 60 / 60 - weeks * 7 * 24 - days * 24
  );
  let minutes = Math.floor(
    difference / 1000 / 60 - weeks * 7 * 24 * 60 - days * 24 * 60 - hours * 60
  );
  let seconds = Math.floor(
    difference / 1000 -
      weeks * 7 * 24 * 60 * 60 -
      days * 24 * 60 * 60 -
      hours * 60 * 60 -
      minutes * 60
  );
  let displayDate = "now";
  ["days", "hours", "minutes", "seconds"].some((q) => {
    if (eval(q) > 0) {
      if (eval(q) === 1) {
        displayDate = eval(q) + " " + q.slice(0, q.length - 1);
      } else {
        displayDate = eval(q) + " " + q;
      }
      return true;
    }
  });
  if (isNeedsSuffix && displayDate !== "now") {
    displayDate = displayDate + suffix;
  }
  if (weeks > 0) {
    displayDate = new Date(date).toDateString();
  }
  return displayDate;
};

export const logError = (error) => {
  if (APP_MODE == "dev") console.log(error);
};

export const setTimeoutToPromise = (time) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, time);
  });
};

const requiredCheck = (data, key) => {
  if (data[key] !== 0 && !data[key]) {
    return {
      name: "is required",
      column: key,
    };
  }
  return false;
};

const numberTypeCheck = (data, key) => {
  if (data[key] !== 0 && isNaN(data[key])) {
    return {
      name: "required number",
      column: key,
    };
  }
  return false;
};

const listValueCheck = (data, lists, key, listKey) => {
  if (!data[key]) {
    return false;
  }
  let valid = lists[listKey]?.items?.find((item) => {
    return item.value === data[key];
  });
  if (!valid) {
    return {
      name: `Enter valid ${key}`,
      column: key,
    };
  }
  return false;
};

const validations = {
  category: {
    required: (data) => requiredCheck(data, "category"),
  },
  type: {
    required: (data) => requiredCheck(data, "type"),
  },
  quantity: {
    required: (data) => requiredCheck(data, "quantity"),
    type: (data) => numberTypeCheck(data, "quantity"),
  },
  part_number: {
    required: (data) =>
      data.type === "Active" && requiredCheck(data, "part_number"),
  },
  // package: {
  //   required: (data) => requiredCheck(data, "package"),
  // },
  // refrences: {
  //   required: (data) => requiredCheck(data, "refrences"),
  // },
};

const quantityStockCheck = (data, key) => {
  if (isNaN(data[key])) {
    return {
      name: "required number",
      column: key,
    };
  }
  if (data[key] === 0) {
    return {
      name: "out of stock",
      column: key,
    };
  }
  if (data[key] < data["reorder"]) {
    return {
      name: "Low in stock re-order required",
      column: key,
    };
  }
  return false;
};

const stockQuantityValidations = {
  available_quantity: {
    type: (data) => quantityStockCheck(data, "available_quantity"),
  },
};

export const bomItemsValidation = (data) => {
  let errors = {};
  data.map((item) => {
    let error = {
      row: item.serial_number,
      errors: {},
    };
    Object.keys(validations).map((column) => {
      Object.values(validations[column]).map((fn) => {
        let err = fn(item);
        if (err) {
          error.errors[column] = err;
        }
      });
    });
    if (Object.keys(error.errors).length) {
      errors[item.serial_number] = error;
    }
  });
  return errors;
};

export const stockQuantityValidation = (data) => {
  let errors = {};
  data.map((item) => {
    let error = {
      row: item._id,
      errors: {},
    };
    Object.values(stockQuantityValidations["available_quantity"]).map((fn) => {
      let err = fn(item);
      if (err) {
        error.errors["available_quantity"] = err;
      }
    });

    if (Object.keys(error.errors).length) {
      errors[item._id] = error;
    }
  });
  return errors;
};

export const getErrorOrderedItems = (errors, data, key) => {
  let errorItems = [];
  let items = [];
  data.map((item) => {
    if (errors[item[key]]) {
      errorItems.push(item);
    } else {
      items.push(item);
    }
  });
  return [...errorItems, ...items];
};

export const getUserName = (user) => {
  return user.last_name
    ? user.first_name + " " + user.last_name
    : user.last_name
    ? user.first_name
    : "Unknown";
};

export const GenerateNotification = (value) => {
  switch (value.type) {
    case "new_lead":
      return `${value.content.name} lead has been created.`;
    default: {
      return "New notification";
    }
  }
};
