import { message } from "antd";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getBookings } from "../../actions/bookings";
import { apiRequest } from "../../api";
import apiPaths from "../../api/paths";
import DynamicTable from "../../components/DynamicTable";
import download from "downloadjs";
import BookingModal from "./components/BookingModal";

function BookedLeads() {
  const [filters, setFilters] = useState({});
  const [showBookingModal, setShowBookingModal] = useState(false);
  const bookings = useSelector((state) => state.lead.bookings) || [];
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { filteredValues = {} } = location.state || {};
  const userData = useSelector((state) => state.token.userData);
  let isAdmin = userData.roleId == 1;

  useEffect(() => {
    dispatch(getBookings()).then((res) => {
      if (res.error) {
        message.error(res.error_description);
      }
    });
    fetchFilters();
  }, []);

  const fetchFilters = async () => {
    const res = await apiRequest({
      path: apiPaths.leads + "/filters",
    });
    if (!res.error) {
      setFilters(res);
    }
  };

  const getColor = (colorClass) => {
    switch (colorClass) {
      case "danger":
        return "red";
      default: {
        return colorClass;
      }
    }
  };

  const downLoadAgreement = async (name) => {
    let res = await apiRequest({
      path: apiPaths.agreements + `/?name=${name}`,
      file: true,
    });
    download(res, name, "pdf");
  };

  const COLUMNS = [
    {
      title: "ID",
      dataIndex: "id",
      width: 50,
      defaultFilteredValue: filteredValues.id,
      filterType: "filter",
      render: (_, r, idx) => idx + 1,
      // filterType: "search",
    },
    {
      title: "Lead",
      dataIndex: "lead.title",
      // fixed: "left",
      width: 150,
      render: (_, record) => {
        return record.lead ? (
          <div>
            <a
              onClick={() => {
                navigate("/leads", {
                  state: {
                    filteredValues: {
                      id: [record.lead.id],
                    },
                  },
                });
              }}
            >
              {record.lead.title}
            </a>
          </div>
        ) : null;
      },
      filterType: "search",
    },
    {
      title: "Booking Name",
      dataIndex: "booking_name",
      // fixed: "left",
      width: 150,
      render: (value) => {
        return (
          <div>
            <div>{value}</div>
          </div>
        );
      },
      filterType: "search",
    },
    {
      title: "Developer",
      dataIndex: "developer.title",
      key: "developer",
      width: 120,
      filterType: "filter",
      filters: filters.developer || [],
      render: (_, record) => record.developer?.title,
    },
    {
      title: "Project",
      dataIndex: "project.title",
      key: "project",
      width: 100,
      filterType: "filter",
      filters: filters.project || [],
      render: (_, record) => record.project?.title,
    },
    {
      title: "Units Details",
      dataIndex: "unit_details",
      width: 220,
      filterType: "search",
    },
    {
      title: "Aggreement",
      dataIndex: "agreement_path",
      // fixed: "left",
      width: 150,
      render: (value) => {
        return value ? (
          <div>
            <a
              onClick={() => {
                downLoadAgreement(value);
              }}
            >
              {value?.split("_").slice(1).join("_")}
            </a>
          </div>
        ) : null;
      },
      filterType: "search",
    },
    {
      title: "Created By",
      dataIndex: "createdBy_info.name",
      // fixed: "left",
      width: 150,
      render: (_, record) => {
        return (
          <div>
            <div>{record.createdBy_info?.name}</div>
          </div>
        );
      },
      filterType: "search",
    },
    {
      title: "Brokerage Perc(%)",
      dataIndex: "brokerage_percent",
      // fixed: "left",
      width: 150,
      render: (_, record) => {
        return (
          <div>
            <div>{record.brokerage_percent}</div>
          </div>
        );
      },
      filterType: "search",
    },
    {
      title: "Brokerage Amount(INR)",
      dataIndex: "brokerage_amount",
      // fixed: "left",
      width: 150,
      render: (_, record) => {
        return (
          <div>
            <div>{record.brokerage_amount}</div>
          </div>
        );
      },
      filterType: "search",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      // fixed: "left",
      width: 150,
      filterType: "search",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      filterType: "date",
      width: "150px",
      render: (value) => {
        return moment(value).format("DD-MM-YYYY hh:mm a");
      },
    },
  ];

  return (
    <div style={{ height: "100%" }}>
      <DynamicTable
        dataSource={bookings}
        columns={COLUMNS}
        // deleteBtn
        showAction={() => true}
        editHandler={(record) => setShowBookingModal(record)}
        showActionColumn={isAdmin ? true : false}
        pagination={false}
        scroll={{ y: "90%" }}
      />
      {showBookingModal ? (
        <BookingModal
          open={true}
          onSave={() => {
            setShowBookingModal(false);
          }}
          data={showBookingModal}
          onCancel={() => {
            setShowBookingModal(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default BookedLeads;
